<!-- pageWrapper -->
<div id="pageWrapper">
    <!-- pageMenuPushWrap -->
    <div class="pageMenuPushWrap pageMenuPushWrap1">
        <!-- bgBaseWrap -->
        <div class="bgBaseWrap">
            <!-- pageHeader -->
            <app-header></app-header>
            <br />
            <br />
            <!-- main -->
            <main>

                <!-- twoColumns -->
                <section class="twoColumns container">
                    <!-- contentFiltersHeadingWrap -->
                    <header class="contentFiltersHeadingWrap row">
                        <div class="col-xs-12 col-sm-10">
                            <!-- breadcrumb -->
                            <ol class="breadcrumb breadcrumb1">
                                <li><a href="#">Home</a></li>
                                <li><a href="#">Packages</a></li>
                                <li class="active">Listing Package View</li>
                            </ol>
                            <h1 class="fontNeuron">Listing Package</h1>
                        </div>
                        <div class="col-xs-12 col-sm-2">
                            <!-- viewFilterLinks -->
                            <!-- <ul class="list-unstyled viewFilterLinks">
                                <li class="active"><a href="#"><i class="fas fa-th-large"></i></a></li>
                                <li><a href="#"><i class="fas fa-list"></i></a></li>
                            </ul>-->
                        </div>
                    </header>
                    <div class="row">
                        <div class="col-xs-12 col-md-8 col-md-push-4">
                            <!-- content -->
                            <div id="content">
                                <header class="row rowHead rowHead2">
                                    <div class="col-xs-12 col-md-4">
                                        <div class="sortGroup">
                                            <input id="searchPackage" class="form-control" (input)="Search()"
                                                type="text" [(ngModel)]="term" autocomplete="off" aria-label="Search"
                                                placeholder="Search&hellip;">
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-4">
                                        <div class="sortGroup">
                                            <strong class="groupTitle fwNormal">Per Page:</strong>
                                            <div class="dropdown">
                                                <button class="dropdown-toggle buttonReset" type="button" id="sortGroup"
                                                    data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">Default Order <i
                                                        class="icn fas fa-chevron-down"></i></button>
                                                <ul class="dropdown-menu dropdown-menu-right"
                                                    aria-labelledby="sortGroup">
                                                    <li (click)="changePerPage(10)"><a>10</a></li>
                                                    <li (click)="changePerPage(25)"><a>25</a></li>
                                                    <li (click)="changePerPage(50)"><a>50</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-4">
                                        <!-- sortGroup (input)="Search()">-->
                                        <div class="sortGroup">
                                            <strong class="groupTitle fwNormal">Sort by:</strong>
                                            <div class="dropdown">
                                                <button class="dropdown-toggle buttonReset" type="button" id="sortGroup"
                                                    data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">Default Order <i
                                                        class="icn fas fa-chevron-down"></i></button>
                                                <ul class="dropdown-menu dropdown-menu-right"
                                                    aria-labelledby="sortGroup">
                                                    <li (click)="sortBy('asc')"><a>Default Order</a></li>
                                                    <li (click)="sortBy('desc')"><a>Package Title</a></li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </header>
                                <!-- isoContentHolder -->
                                <div class="isoContentHolder" style="overflow-y: scroll; overflow-x: hidden;">
                                    <!-- <div class="row"> -->
                                        <div class=" col isoCol sale"   *ngFor="let rowData of obj | paginate: { itemsPerPage: perPageItem, currentPage: p} ">
                                            <!-- postColumn__ListView -->
                                            <article class="postColumn__ListView hasOver bgWhite">
                                                <div class="alignleft noShrink">
                                                    <!-- postColumnImageSlider -->
                                                    <div class="slick-carousel slickSlider postColumnImageSlider">
                                                        <div>
                                                            <div class="imgHolder">
                                                                <a href="properties-single1.html">
                                                                    <img src="https://via.placeholder.com/255x200"
                                                                        alt="image description">
                                                                </a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <!-- postHoverLinskList -->
                                                    <ul class="list-unstyled postHoverLinskList">
                                                        <li><a href="#"><i class="fi flaticon-repeat"></i></a></li>
                                                        <li class="hasOver">
                                                            <a href="#"><i class="fi flaticon-share"></i></a>
                                                            <!-- postColumnSocial -->
                                                            <ul class="list-unstyled socialNetworks postColumnSocial">
                                                                <li><a href="#"><i class="fab fa-facebook-f"></i></a>
                                                                </li>
                                                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                                                <li><a href="#"><i class="fab fa-instagram"></i></a>
                                                                </li>
                                                                <li><a href="#"><i class="fab fa-google"></i></a></li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <!-- linkToFavourite -->
                                                    <a href="#"
                                                        class="linkToFavourite roundedCircle bg-primary textWhite icnHeartBeatAnim"><i
                                                            class="far fa-heart"></i></a>
                                                </div>
                                                <div class="descrWrap">
                                                    <h2 class="fontNeuron text-capitalize"><a
                                                            routerLink="/premium-package-detail">{{rowData.packageTitle}}</a>
                                                    </h2>
                                                    <address>
                                                        <span class="icn"><i class="fi flaticon-pin-1"></i></span>
                                                        <p>The Village, Jersey City, NJ 07302, USA </p>
                                                    </address>
                                                    <div class="stampWrap">
                                                        <span class="btn btnSmall btn-success text-capitalize">For
                                                            Sale</span>
                                                        <time datetime="2011-01-12" class="timing">1 day ago</time>
                                                    </div>
                                                    <div class="priceWrap">
                                                        <h3 class="fontNeuron fwSemi"><span class="textSecondary">$
                                                                490,000</span> <span class="textUnit fwNormal">/
                                                                monthly</span></h3>
                                                        <!-- linkDistributer -->
                                                        <a href="#"
                                                            class="linkDistributer text-capitalize hidden-xs-ph"><i
                                                                class="distributerIcn fi flaticon-social"></i> ali
                                                            tufan</a>
                                                    </div>
                                                    <!-- postColumnFoot -->
                                                    <footer class="postColumnFoot">
                                                        <ul class="list-unstyled">
                                                            <li>
                                                                <strong
                                                                    class="fwNormal elemenBlock text-primary">Area</strong>
                                                                <strong class="fwNormal elemenBlock">2100 m2</strong>
                                                            </li>
                                                            <li>
                                                                <strong
                                                                    class="fwNormal elemenBlock text-primary">Beds</strong>
                                                                <strong class="fwNormal elemenBlock">3</strong>
                                                            </li>
                                                            <li>
                                                                <strong
                                                                    class="fwNormal elemenBlock text-primary">Baths</strong>
                                                                <strong class="fwNormal elemenBlock">2</strong>
                                                            </li>
                                                            <li>
                                                                <strong
                                                                    class="fwNormal elemenBlock text-primary">Garages</strong>
                                                                <strong class="fwNormal elemenBlock">1</strong>
                                                            </li>
                                                        </ul>
                                                    </footer>
                                                </div>
                                            </article>
                                        </div>

                                    <!-- </div> -->

                                </div>
                                <!-- navigation pagination -->
                                <!-- <nav class="navigation pagination pagination1 fontNeuron" role="navigation" >
                                        <div class="nav-links" > -->
                                <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
                                <pagination-controls (pageChange)="p = $event" (pageBoundsCorrection)="p = $event"
                                    maxSize="19" directionLinks="true" responsive="true" previousLabel="Previous"
                                    nextLabel="Next" screenReaderPaginationLabel="Pagination"
                                    screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page">
                                </pagination-controls>




                                <!-- </div>
                                    </nav>  -->



                                <!-- navigation pagination -->
                                <!-- <nav class="navigation pagination pagination1 fontNeuron" role="navigation">
    <div class="nav-links">
        <a class="prev page-numbers" href="#">Previous</a>
        <a class="page-numbers" href="#">1</a>
        <span class="page-numbers current">2</span>
        <a class="page-numbers" href="#">3</a>
        <span class="page-numbers dots">&hellip;</span>
        <a class="page-numbers" href="#">22</a>
        <a class="next page-numbers" href="#">Next</a>
    </div>
</nav> -->

                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
        <!-- pageFooterBlock -->
        <app-footer></app-footer>
    </div>
    <!-- pagePopupWrap -->
    <div class="popup-holder">
        <div id="popup1" class="lightbox-demo">
            <!-- popupLoginFrom -->
            <form action="#" class="popupHolderWrap bgLightDark center-block popupLarge popupLoginFrom">
                <ul class="list-unstyled popupTabsetList text-center fwBold text-uppercase fontNeuron">
                    <li class="active"><a href="#tab01">LOGIN</a></li>
                    <li><a href="#tab02">REGISTER</a></li>
                </ul>
                <div class="tab-content">
                    <div id="tab01" class="tab-active">
                        <div class="popupColsHolder">
                            <div class="col bgWhite">
                                <div class="form-group">
                                    <input type="text" class="form-control elemenBlock" placeholder="User Namer">
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control elemenBlock" placeholder="Password">
                                </div>
                                <div class="form-group">
                                    <label class="fwNormal customLabelCheck">
                                        <input type="checkbox" class="customFormInputReset">
                                        <span class="fakeCheckbox"></span>
                                        <span class="fakeLabel">Remember me</span>
                                    </label>
                                </div>
                                <button type="submit"
                                    class="btn btnSecondary elemenBlock fontNeuron fwNormal text-uppercase btnSubmit">LOGIN</button>
                                <a href="#" class="linkForget">Forgot Password?</a>
                            </div>
                            <div class="col">
                                <p>You Can Login using your facebook Profile or Google account</p>
                                <button type="button" class="btnSocial btnFb elemenBlock"><i
                                        class="fab fa-facebook-f btnIco"></i> Facebook Connect</button>
                                <button type="button" class="btnSocial btnTw elemenBlock"><i
                                        class="fab fa-twitter btnIco"></i> Twitter Connect</button>
                                <button type="button" class="btnSocial btnG elemenBlock"><i
                                        class="fab fa-google btnIco"></i> Google Connect</button>
                            </div>
                        </div>
                    </div>
                    <div id="tab02">
                        <div class="popupColsHolder">
                            <div class="col bgWhite">
                                <div class="form-group">
                                    <input type="text" class="form-control elemenBlock" placeholder="Full Name">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control elemenBlock" placeholder="User Name">
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control elemenBlock" placeholder="Password">
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control elemenBlock"
                                        placeholder="Confirm Password">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control elemenBlock" placeholder="Email Address">
                                </div>
                                <div class="form-group">
                                    <select data-placeholder="Type" class="chosen-select">
                                        <option value="1">For Rent</option>
                                        <option value="2">For Sale</option>
                                        <option value="3">House</option>
                                        <option value="4">Property</option>
                                        <option value="5">Real Estate</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="fwNormal customLabelCheck">
                                        <input type="checkbox" class="customFormInputReset" checked>
                                        <span class="fakeCheckbox"></span>
                                        <span class="fakeLabel">I have read and agree to the Term of Service.</span>
                                    </label>
                                </div>
                                <button type="submit"
                                    class="btn btn-primary elemenBlock fontNeuron fwNormal text-uppercase btnSubmit">REGISTER</button>
                            </div>
                            <div class="col">
                                <p>You Can Login using your facebook Profile or Google account</p>
                                <button type="button" class="btnSocial btnFb elemenBlock"><i
                                        class="fab fa-facebook-f btnIco"></i> Facebook Connect</button>
                                <button type="button" class="btnSocial btnTw elemenBlock"><i
                                        class="fab fa-twitter btnIco"></i> Twitter Connect</button>
                                <button type="button" class="btnSocial btnG elemenBlock"><i
                                        class="fab fa-google btnIco"></i> Google Connect</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>