<!-- pageWrapper -->
<div id="pageWrapper">
  <!-- pageMenuPushWrap -->
  <div class="pageMenuPushWrap pageMenuPushWrap1">
    <!-- bgBaseWrap -->
    <div class="bgBaseWrap">
      <!-- pageHeader -->
      <app-header></app-header>
      <!-- main -->
      <main>
        <!-- findFormBlock -->
        <form action="#" class="findFormBlock findFormBlock2 bgLightDark hasShadowTop offsetHeaderTop">
          <div class="container">
            <div class="row align-center d-flex">
              <div class="col-xs-12 col-sm-5 col-md-4 col-lg-5">
                <div class="form-group">
                  <input type="text" class="form-control elemenBlock noBdr sizeMedium"
                    placeholder="Enter address e.g. street, city and state or zip">
                </div>
              </div>
              <div class="col-xs-12 col-sm-4 col-md-2">
                <div class="form-group">
                  <i class="fi flaticon-target icnInput"></i>
                  <input type="text" class="form-control elemenBlock noBdr sizeMedium" placeholder="All Cities">
                </div>
              </div>
              <div class="col-xs-12 col-sm-3 col-md-2">
                <div class="form-group">
                  <select data-placeholder="All Areas" class="chosen-select noBdr sizeMedium">
                    <option value="1">All Areas</option>
                    <option value="2">All Areas</option>
                    <option value="2">All Areas</option>
                    <option value="2">All Areas</option>
                  </select>
                </div>
              </div>
              <div class="col-xs-12 col-md-4 col-lg-3">
                <div class="btnsWrap">
                  <!-- otherFeaturesOpener -->
                  <a class="btnPlus otherFeaturesOpener text-capitalize noTopBottom" role="button"
                    data-toggle="collapse" href="#otherFeaturescollapse" aria-expanded="false"
                    aria-controls="otherFeaturescollapse">
                    <i class="fas btnIcn fa-plus-circle text-info opener"></i>
                    <i class="fas fa-minus-circle closer btnIcn text-info"></i>
                    Advanced
                  </a>
                  <button type="button"
                    class="btn btnDark buttonSmall text-uppercase fontNeuron hidden-xs">SEARCH</button>
                </div>
              </div>
            </div>
            <!-- orderRow -->
            <div class="row">
              <div class="col-xs-12">
                <!-- otherFeaturesCollapse -->
                <div class="collapse otherFeaturesCollapse" id="otherFeaturescollapse">
                  <!-- checkList -->
                  <ul class="list-unstyled checkList text-primary">
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Air conditioning</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Cofee pot</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Fan</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Hi-fi</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Balcony</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Computer</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Fridge</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Internet</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Bedding</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Cot</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Grill</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Iron</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Cable TV</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Dishwasher</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Hairdryer</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Juicer</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Cleaning after exit</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">DVD</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Heating</span>
                      </label>
                    </li>
                    <li>
                      <label class="fwNormal customLabelCheck">
                        <input type="checkbox" class="customFormInputReset">
                        <span class="fakeCheckbox"></span>
                        <span class="fakeLabel">Lift</span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xs-12 hidden-sm hidden-md hidden-lg">
                <button type="button" class="btn btnDark text-uppercase fontNeuron">SEARCH</button>
              </div>
            </div>
          </div>
        </form>
        <!-- content -->
        <section id="content" class="container pabIndent">
          <!-- contentFiltersHeadingWrap -->
          <header class="contentFiltersHeadingWrap row">
            <div class="col-xs-12 col-sm-10">
              <!-- breadcrumb -->
              <ol class="breadcrumb breadcrumb1">
                <li><a href="#">Home</a></li>
                <li><a href="#">Pages</a></li>
                <li class="active">Membership</li>
              </ol>
              <h1 class="fontNeuron">Profile</h1>
            </div>
            <div class="col-xs-12 col-sm-2">
              <!-- viewFilterLinks -->
              <ul class="list-unstyled viewFilterLinks">
                <li><a href="#"><i class="fi flaticon-share"></i></a></li>
                <li><a href="#"><i class="fi flaticon-printer"></i></a></li>
              </ul>
            </div>
          </header>
          <!-- userProfile -->
          <div class="userProfile">
            <div class="row">
              <div class="col-xs-12 col-sm-4 col-lg-3">
                <!-- profileSidebar -->
                <aside class="profileSidebar">
                  <header class="head">
                    <div class="imgProfile">
                      <img  *ngIf="!image" src="https://via.placeholder.com/74x74" alt="" width="74" height="74">
                      
                          <img *ngIf="image" [src]="image" alt="" width="74" height="74">
                    </div>
                    <div class="info">
                      <span class="text">{{username}}</span>
                      <span class="text"><a href="#" class="link">Change Password</a></span>
                    </div>
                  </header>
                  <ul class="navUser list-unstyled">
                    <li>
                      <a href="#">
                        <i class="far fa-user"></i>
                        <span>Account Settings</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fi flaticon-house"></i>
                        <span>My Properties</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="far fa-heart"></i>
                        <span>Favorited Properties</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-plus"></i>
                        <span>Submit Property</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-sign-out-alt"></i>
                        <span>Logout</span>
                      </a>
                    </li>
                  </ul>
                </aside>
              </div>
              <div class="col-xs-12 col-sm-8 col-lg-9">
                <!-- accountData -->
                <div class="accountData">
                  <form [formGroup]="updateProfileForm" >
                    <div class="head">
                      <h4 class="fontNeuron">Account Settings</h4>
                    </div>
                    <div class="accountHolder">
                      <div class="imgProfile">
                        <div class="imgThumbnail">
                         
                          <img *ngIf="!image" src="https://via.placeholder.com/200x200" alt="" width="200" height="200">
                          <img *ngIf="image" [src]="image" alt="" width="200" height="200">
                          
                          <ng-picker-overlay
                          [apikey]="apikey"
                          (uploadSuccess)="onUploadSuccess($event)"
                          (uploadError)="onUploadError($event)">
                          
                          <a href="#" class="btn btn-info"><i class="fa fa-upload"></i> Upload Photo</a>
                        </ng-picker-overlay>
                        </div>
                        <span class="text text-center"></span>

                      </div>
                      <div class="accountContent">
                        <div class="form-group" >
                          <label for="userName">Full Name</label>    
                          <input type="text" class="form-control elemenBlock" placeholder="Enter Name" formControlName="userName">
                          <div class="invalid-feedback" *ngIf="updateProfileForm.get('userName').touched && updateProfileForm.get('userName').invalid">
                            <span class="text-danger" *ngIf="updateProfileForm.get('userName').errors.required">Enter user name.</span>
                          </div>
                        </div>
                         
                        <div class="form-group">
                          <label for="email">Email</label>
                          <input type="email" readonly class="form-control elemenBlock" placeholder="Enter email"   formControlName="email">
                          <div class="invalid-feedback" *ngIf="updateProfileForm.get('email').touched && updateProfileForm.get('email').invalid">
                            <span class="text-danger" *ngIf="updateProfileForm.get('email').errors.required">Enter Email</span>
                            <span class="text-danger" *ngIf="updateProfileForm.get('email').errors.email">Email must be valid</span>
                          </div>
                       
                        </div>

                        <div class="form-group">
                          <label for="mobile">Phone</label>
                          <input type="tel" class="form-control" placeholder="Enter Mobile" formControlName="mobile">
                          <div class="invalid-feedback" *ngIf="updateProfileForm.get('mobile').touched && updateProfileForm.get('mobile').invalid">
                            <span class="text-danger" *ngIf="updateProfileForm.get('mobile').errors.required">Enter mobile.</span>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="itemN-4">Biography</label>
                          <textarea id="itemN-4" class="form-control"
                            placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et dui vestibulum, bibendum purus sit amet, vulputate mauris. Ut adipiscing gravida tincidunt. Duis euismod placerat rhoncus.Phasellus mollis imperdiet placerat"></textarea>
                        </div>
                          <button type="submit" (click)="updateUserDetails()" class="btn alighRight btnSecondary buttonL fontNeuron" >Update 
                          Profile</button>
                        </div>
                    </div>
                  </form>
                </div>
                <!-- accountData -->
                <div class="accountData">
                  <form [formGroup]="changePasswordForm" >
                    <div class="head">
                      <h4 class="fontNeuron">Change Password</h4>
                    </div>
                    <div class="accountHolder">
                      <div class="accountContent">
                        <div class="row">
                          <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                              <label for="password">Current Password</label>
                              <input type="password" class="form-control elemenBlock" placeholder="Password" formControlName="password">
                              <div class="invalid-feedback" *ngIf="changePasswordForm.get('password').touched && changePasswordForm.get('password').invalid">
                                <span class="text-danger" *ngIf="changePasswordForm.get('password').errors.required">Enter password.</span>
                                <span class="text-danger" *ngIf="changePasswordForm.get('password').hasError('minlength')">Minimum length should be 6.<br /></span>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                              <label for="newPassword">New Password</label>
                              <input type="password" class="form-control elemenBlock" placeholder="New Password" formControlName="newPassword">
                              <div class="invalid-feedback" *ngIf="changePasswordForm.get('newPassword').touched && changePasswordForm.get('newPassword').invalid">
                                <span class="text-danger" *ngIf="changePasswordForm.get('newPassword').errors.required">Enter new password.</span>
                                <span class="text-danger" *ngIf="changePasswordForm.get('newPassword').hasError('minlength')">Minimum length should be 6.<br /></span>
                              </div>
                            </div>
                          </div>
                          <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                              <label for="confirmNewPassword">Confirm New Password</label>
                              <input type="password" class="form-control elemenBlock" placeholder="Confirm New Password" formControlName="confirmNewPassword">
                              <div class="invalid-feedback" *ngIf="changePasswordForm.get('confirmNewPassword').touched && changePasswordForm.get('confirmNewPassword').invalid">
                                <span class="text-danger" *ngIf="changePasswordForm.get('confirmNewPassword').errors.required">Enter new password.</span>
                                <span  *ngIf="changePasswordForm.get('confirmNewPassword').errors.mustMatch">Passwords must match<br /></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button type="submit" (click)="passwordChange()" class="btn alighRight btnSecondary buttonL fontNeuron" >Change
                          Password</button>
                      </div>
                    </div>
                  </form>
                </div>
                <!-- accountData -->
                <div class="accountData">
                  <form>
                    <div class="head">
                      <h4 class="fontNeuron">Social Profiles</h4>
                    </div>
                    <div class="accountHolder">
                      <div class="accountContent">
                        <div class="row">
                          <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                              <label for="itemN-8">Facebook URL</label>
                              <input type="text" class="form-control" placeholder="www.facebook.com/PolygonTheme"
                                id="itemN-8">
                            </div>
                          </div>
                          <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                              <label for="itemN-9">Twitter URL</label>
                              <input type="text" class="form-control" placeholder="PolygonTheme" id="itemN-9">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                              <label for="itemN-10">Linkedin URL</label>
                              <input type="text" class="form-control" placeholder="PolygonTheme" id="itemN-10">
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-6">
                            <div class="form-group">
                              <label for="itemN-11">Instagram URL</label>
                              <input type="text" class="form-control" placeholder="PolygonTheme" id="itemN-11">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                              <label for="itemN-12">Google Plus URL</label>
                              <input type="text" class="form-control" placeholder="PolygonTheme" id="itemN-12">
                            </div>
                          </div>
                          <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                              <label for="itemN-13">Skype</label>
                              <input type="text" class="form-control" placeholder="PolygonTheme" id="itemN-13">
                            </div>
                          </div>
                        </div>
                        <button type="submit" class="btn alighRight btnSecondary buttonL fontNeuron">Save
                          Changes</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- signupFormAside -->
        <form action="#" class="bgWhite signupFormAside">
          <div class="container signupFormAsideHolder">
            <span class="icnAbsolute elemenBlock fi flaticon-message"></span>
            <div class="col-xs-12 col-md-offset-2 col-md-6">
              <h3 class="fontNeuron textSecondary">Sign up for our newsletter.</h3>
              <p>Lorem molestie odio. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
            </div>
            <div class="col-xs-12 col-md-4">
              <div class="form-group">
                <input type="email" id="email" class="form-control elemenBlock" placeholder="Your email">
                <button type="button" class="buttonReset fi flaticon-navigation"></button>
              </div>
            </div>
          </div>
        </form>
      </main>
    </div>
    <!-- pageFooterBlock -->
    <app-footer></app-footer>
  </div>
</div>