import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ChangeUserProfileComponent } from './components/change-user-profile/change-user-profile.component';
import { ListPackagesComponent } from './list-packages/list-packages.component';
import { LatestPackageDetailComponent } from './latest-package-detail/latest-package-detail.component';
import { PremiumPackageDetailComponent } from './premium-package-detail/premium-package-detail.component';
import { LatestPackageListComponent } from './latest-package-list/latest-package-list.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'user-profile',
    component: UserProfileComponent
  },
  {
    path: 'change-profile',
    component: ChangeUserProfileComponent
  },
  {
    path: 'list-packages',
    component: ListPackagesComponent
  },
  {
    path: 'latest-package-list',
    component: LatestPackageListComponent
  },
  {
    path: 'latest-package-detail',
    component: LatestPackageDetailComponent
  },
  {
    path: 'premium-package-detail',
    component: PremiumPackageDetailComponent
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
