import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from "../services/localStorage/local-storage.service";
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-latest-package-list',
  templateUrl: './latest-package-list.component.html',
  styleUrls: ['./latest-package-list.component.css']
})
export class LatestPackageListComponent implements OnInit {

  userDetail:any;
  rowData: any;
  packageTitle:string;
   obj=[
    {
      "idPackage": "1",
      "idPackageType": 10,
      "packageTitle": "Package1",
      "packageSlug": "DIM-EUR",
      "idPairSource": "1",
      "idPairTarget": "2",
      "initialValue": "100",
      "lockupPeriod": 12,
      "releasedAt": "2020-10-29T00:00:00+00:00",
      "isPrimary": "1",
      "isActive": "1"
    },
    {
      "idPackage": "2",
      "idPackageType": 11,
      "packageTitle": "Package2",
      "packageSlug": "DIM-EUR",
      "idPairSource": "1",
      "idPairTarget": "2",
      "initialValue": "100",
      "lockupPeriod": 12,
      "releasedAt": "2020-10-29T00:00:00+00:00",
      "isPrimary": "1",
      "isActive": "1"
    },
    {
      "idPackage": "3",
      "idPackageType": 12,
      "packageTitle": "Package3",
      "packageSlug": "DIM-EUR",
      "idPairSource": "1",
      "idPairTarget": "2",
      "initialValue": "100",
      "lockupPeriod": 12,
      "releasedAt": "2020-10-29T00:00:00+00:00",
      "isPrimary": "1",
      "isActive": "1"
    },
  ];
  

  constructor( private authService: AuthService, private toastr: ToastrService, private localStorage: LocalStorageService) { }

  ngOnInit(): void {
   
   this. userDetail=this.localStorage.getLoggedInUserData();
 
  //  const obj={
  //   idUser: this.userDetail.idUser,
  //   sessionKey: this.userDetail.Key.sessionKey,
  //   publicKey: this.userDetail.Key.publicKey,
  //   accessKey: '',
  //   page: '2',
  //   limit: '4',
  //   search: ''
  //   }
  // console.log('list package api param', obj);

  // this.authService.listPackage(obj)
  //   .subscribe(listPackageRes=>{ 
  //     this.rowData=listPackageRes; 
  //     console.log( 'rowdata',this.rowData);
  //   console.log('list package response', listPackageRes);
  //   this.rowData=listPackageRes;
    
  // });
 
  }

     //initializing p to one
     p: number = 1;

  listPackage () {
    
    
    const obj={
      idUser: this.userDetail.idUser,
      sessionKey: this.userDetail.Key.sessionKey,
      publicKey: this.userDetail.Key.publicKey,
      accessKey: '',
      page: '',
      limit: '',
      search: ''
      }
    console.log('list package api param', obj);

    this.authService.listPackage(obj).subscribe((listPackageRes: any) => {
     
      console.log('list package response', listPackageRes);
      this.rowData=listPackageRes;
      if (listPackageRes.error === '' || listPackageRes.error === undefined) {
        this.toastr.success('', 'Show list of package', {
          disableTimeOut: true,
          tapToDismiss: false,
          closeButton: true
        });
        
      } else {
        this.toastr.error(listPackageRes.error['Error Description'], listPackageRes.error['Error Msg'], {
          disableTimeOut: true,
          tapToDismiss: false,
          closeButton: true
        });
      }
    });
  }

Search()
{
// if(this.packageTitle !="")
// {
//   this.obj=this.obj.filter(res=>{
//          return res.packageTitle.toLocalLowerCase().match(this.packageTitle.toLocalLowerCase());
// }
// else if(this.packageTitle==  "")
// {
//   this.ngOnInit();
// }
  
   
}

}
