import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastrService } from "ngx-toastr";
import { LocalStorageService } from "../../services/localStorage/local-storage.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  userDetail:any;
  rowData: any;

  constructor( private authService: AuthService, private toastr: ToastrService,private localStorage: LocalStorageService
   
  ) { }

  ngOnInit(): void {

  //   this. userDetail=this.localStorage.getLoggedInUserData();
   
  //   const obj={
  //    idUser: this.userDetail.idUser,
  //    sessionKey: this.userDetail.Key.sessionKey,
  //    publicKey: this.userDetail.Key.publicKey,
  //    accessKey: '',
  //    page: '2',
  //    limit: '4',
  //    search: ''
  //    }
  //  console.log('list package api param', obj);
 
  //  this.authService.listPackage(obj)
  //    .subscribe(listPackageRes=>{ 
  //      this.rowData=listPackageRes; 
  //      console.log( 'rowdata',this.rowData);
  //    console.log('list package response', listPackageRes);
  //    this.rowData=listPackageRes;

  //    this.authService.listPackage(obj).subscribe((listPackageRes: any) => {
  //     console.log('forgot pass res', listPackageRes);
  //     this.rowData=listPackageRes;

  //    if (listPackageRes.error === '' || listPackageRes.error === undefined) {
  //     this.toastr.success(listPackageRes.data['Success Description'], listPackageRes.data['Success Msg'], {
  //       disableTimeOut: true,
  //       tapToDismiss: false,
  //       closeButton: true
  //     });
  //   } else {
  //     this.toastr.error(listPackageRes.error['Error Description'], listPackageRes.error['Error Msg'], {
  //       disableTimeOut: true,
  //       tapToDismiss: false,
  //       closeButton: true
  //     });
  //   }
     
  //  });

  }

}
