<!-- pageWrapper -->
<div id="pageWrapper">
  <!-- pageMenuPushWrap -->
  <div class="pageMenuPushWrap pageMenuPushWrap1">
    <!-- bgBaseWrap -->
    <div class="bgBaseWrap">
      <!-- pageHeader -->
      <app-header></app-header>
      <!-- main -->
      <main>
        <!-- content -->
        <div id="content" class="container pCombine">
          <section class="block404 text-center">
            <h2 class="fontNeuron">404<span>Page not found.</span></h2>
            <p>The page you are looking for was removed or does not exist.</p>
            
            <a routerLink="/home" class="buttonL btn btnDark fontNeuron">Back to Home</a>
          </section>
        </div>
        <!-- signupFormAside -->
        <form action="#" class="bgWhite signupFormAside">
          <div class="container signupFormAsideHolder">
            <span class="icnAbsolute elemenBlock fi flaticon-message"></span>
            <div class="col-xs-12 col-md-offset-2 col-md-6">
              <h3 class="fontNeuron textSecondary">Sign up for our newsletter.</h3>
              <p>Lorem molestie odio. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
            </div>
            <div class="col-xs-12 col-md-4">
              <div class="form-group">
                <input type="email" id="email" class="form-control elemenBlock" placeholder="Your email">
                <button type="button" class="buttonReset fi flaticon-navigation"></button>
              </div>
            </div>
          </div>
        </form>
      </main>
    </div>
    <!-- pageFooterBlock -->
    <app-footer></app-footer>
  </div>
</div>