import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastrService } from "ngx-toastr";
import { LocalStorageService } from "../../services/localStorage/local-storage.service";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  idUser:any;
  activationKey:any;
  constructor(private fb: FormBuilder, private authService: AuthService, private toastr: ToastrService, private localStorage: LocalStorageService,
    private route: ActivatedRoute
  ) { }
  // loading initially false
  isLoadingResetPass=false;
   // reset password form group
   resetPasswordForm = this.fb.group({
    password: ['', [Validators.required]]
  });
 

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.idUser = params.get('idUser');
      console.log(this.idUser);
      this.activationKey=params.get('activationKey');
    });
  }

  //reset password
  resetPassword()
  {
    this.isLoadingResetPass = true;

    const obj = {
      "idUser":this.idUser,
      "activationKey":this.activationKey,
      "password": this.resetPasswordForm.get('password').value
    }

    console.log('', obj);
    
    this.authService.resetPassword(obj).subscribe((resetPassRes: any) => {
      console.log('reset pass res', resetPassRes);
      this.isLoadingResetPass = false;
      if (resetPassRes.error === '' || resetPassRes.error === undefined) {
        this.toastr.success(resetPassRes.data['Success Description'], resetPassRes.data['Success Msg'], {
          disableTimeOut: true,
          tapToDismiss: false,
          closeButton: true
        });
      } else {
        this.toastr.error(resetPassRes.error['Error Description'], resetPassRes.error['Error Msg'], {
          disableTimeOut: true,
          tapToDismiss: false,
          closeButton: true
        });
      }
    })
  }
  
}
