<!-- pageWrapper -->
<div id="pageWrapper">
    <!-- pageMenuPushWrap -->
    <div class="pageMenuPushWrap pageMenuPushWrap1">
      <!-- bgBaseWrap -->
      <div class="bgBaseWrap">
        <!-- pageHeader -->
        <app-header></app-header>
        <!-- main -->
				<main>
					<!-- propertyIntroBlock -->
					<section class="propertyIntroBlock">
						<!-- propertyIntroImageSlider -->
						<div class="slick-fade slickSlider introSlider propertyIntroImageSlider">
							<div>
								<span class="bgCover elemenBlock introSlide" style="background-image: url(https://via.placeholder.com/1920x750);"></span>
							</div>
							<div>
								<span class="bgCover elemenBlock introSlide" style="background-image: url(https://via.placeholder.com/1920x750);"></span>
							</div>
							<div>
								<span class="bgCover elemenBlock introSlide" style="background-image: url(https://via.placeholder.com/1920x750);"></span>
							</div>
						</div>
						<!-- rightPanelList -->
						<ul class="list-unstyled rightPanelList hidden-xs">
							<li>
								<a href="#" class="hasOver">
									<span class="fi flaticon-photo icn"></span>
									<strong class="fwNormal textCaption"><span class="indentWrap elemenBlock">View Photos</span></strong>
								</a>
							</li>
							<li>
								<a href="#" class="hasOver">
									<span class="fi flaticon-placeholder icn"></span>
									<strong class="fwNormal textCaption"><span class="indentWrap elemenBlock">Map View</span></strong>
								</a>
							</li>
							<li>
								<a href="#" class="hasOver">
									<span class="fi flaticon-street-view icn"></span>
									<strong class="fwNormal textCaption"><span class="indentWrap elemenBlock">Street View</span></strong>
								</a>
							</li>
							<li>
								<a href="#" class="hasOver">
									<span class="fi flaticon-360-degrees icn"></span>
									<strong class="fwNormal textCaption"><span class="indentWrap elemenBlock">360 Virtual Tour</span></strong>
								</a>
							</li>
						</ul>
						<!-- propertyIntroCaption -->
						<div class="container propertyIntroCaption textWhite">
							<div class="align">
								<div class="row alignEnd">
									<div class="col-xs-12 col col-sm-8">
										<!-- breadcrumb -->
										<ol class="breadcrumb breadcrumb1">
											<li><a href="#">Home</a></li>
											<li><a href="#">Properties</a></li>
											<li class="active">Listing Grid View</li>
										</ol>
										<h1 class="fontNeuron textWhite">Villa on Hollywood Boulevard <a href="#" class="btn btnSmall btn-success fontBase">For Sale</a></h1>
										<!-- captionAddress -->
										<address class="captionAddress">
											<span class="icn"><i class="fi flaticon-pin-1"></i></span>
											<p>The Village, Jersey City, NJ 07302, USA</p>
										</address>
									</div>
									<div class="col-xs-12 col-sm-4 justify-end">
										<!-- captionActionsList -->
										<ul class="list-unstyled captionActionsList w100">
											<li><a href="#"><i class="fi flaticon-repeat"></i></a></li>
											<li class="hasOver">
												<a href="#">
													<i class="fi flaticon-share"></i>
												</a>
												<!-- postColumnSocial -->
												<ul class="list-unstyled socialNetworks postColumnSocial">
													<li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
													<li><a href="#"><i class="fab fa-twitter"></i></a></li>
													<li><a href="#"><i class="fab fa-instagram"></i></a></li>
													<li><a href="#"><i class="fab fa-google"></i></a></li>
												</ul>
											</li>
											<li><a href="#"><i class="far fa-heart"></i></a></li>
											<li><a href="#"><i class="fi flaticon-printer"></i></a></li>
										</ul>
										<strong class="elemenBlock textWhite price textWhite fwSemi fontNeuron w100">$ 490,000</strong>
										<strong class="elemenBlock fwNormal fontNeuron subtitle w100">$770 / sq ft</strong>
									</div>
								</div>
							</div>
						</div>
					</section>
					<!-- anchorNav -->
					<nav class="bgWhite hasShadow anchorNav">
						<div class="container">
							<!-- anchorNavigationList -->
							<ul class="list-unstyled anchorNavigationList text-capitalize">
								<li><a href="#Detail">Detail</a></li>
								<li><a href="#Description">Description</a></li>
								<li><a href="#Address">Address</a></li>
							</ul>
						</div>
					</nav>
					<!-- twoColumns -->
					<div class="twoColumns indentTop container">
						<div class="row">
							<div class="col-xs-12 col-sm-12 col-md-8">
								<!-- content -->
								<article id="content">
									<!-- boxPanelBlock -->
									<section id="Detail" class="accountData">
										<div class="head">
											<h2 class="fontNeuron">Detail</h2>
										</div>
										<div class="listpanel-content">
											<ul>
												<li><span>Package ID</span><span class="righttext">: {{obj.idPackage}}</span></li>
												<li><span>Package Type ID</span><span class="righttext">: {{obj.idPackageType}}</span></li>
												<li><span>Package Title</span><span class="righttext">: {{obj.packageTitle}}</span></li>
												<li><span>package Slug</span><span class="righttext">: {{obj.packageSlug}}</span></li>
												<li><span>Pair Source ID</span><span class="righttext">: {{obj.idPairSource}}</span></li>
												<li><span>Pair Target ID</span><span class="righttext">: {{obj.idPairTarget}}</span></li>
											</ul>
											<ul>
												<li><span>Initial Value</span><span class="righttext">:{{obj.initialValue}}</span></li>
												<li><span>Lockup Period</span><span class="righttext">: {{obj.lockupPeriod}}</span></li>
												<li><span>Released At</span><span class="righttext">: {{obj.releasedAt|date}}</span></li>
												<li><span>isPrimary</span><span class="righttext">: {{obj.isPrimary}}</span></li>
												<li><span>isActive</span><span class="righttext">: {{obj.isActive}}</span></li>
											
											</ul>
										</div>
									</section>
									<section id="Description" class="accountData">
		                                <div class="head">
		                                  <h4 class="fontNeuron">Description</h4>
		                                </div>
		                            	<p>Ut euismod ultricies sollicitudin. Curabitur sed dapibus nulla. Nulla eget iaculis lectus. Mauris ac maximusneque. Nam in mauris quis libero sodales eleifend. Morbi varius, nulla sit amet rutrum elementum, est elit finibus tellus, ut tristique elit risus at metus. Sed fermentum, lorem vitae efficitur imperdiet, neque velit tristique turpis, et iaculis mi tortor finibus turpis.</p>
		                            </section>
		                            <section id="Address" class="accountData address">
										<div class="head">
											<h4 class="fontNeuron">Detail</h4>
										</div>
										<div class="listpanel-content widthauto address paddingzero">
											<ul>
												<li><span>Address</span><span class="righttext">: The Village, Jersey City</span></li>
												<li><span>Zip/Postal Code</span><span class="righttext">: 07305</span></li>
												<li><span>City</span><span class="righttext">: Chicago</span></li>
												<li><span>Neighborhood</span><span class="righttext">: Hermosa</span></li>
												<li><span>State/county</span><span class="righttext">: New Jersey</span></li>
												<li><span>Country</span><span class="righttext">: United States</span></li>
											</ul>
										</div>
									</section>
								</article>
							</div>
							<div class="col-xs-12 col-sm-12 col-md-4">
								<aside id="sidebar">
									<!-- contactFormPage -->
									<section class="contactFormPage widget">
										<div class="hb-headarea">
											<div class="holder">
												<figure class="hb-author-img pull-left">
													<a href="#"><img [src]="image" alt="image description"></a>
												</figure>
												<div class="pull-left">
													<div class="hb-headcontent">
														<!--<h3>Bostan Homes</h3>-->
														<span>{{username}}</span>
														<a href="#">View Listings</a>
													</div>
												</div>
											</div>
											<div class="hb-numberbox text-center">
												<h3><i class="fi flaticon-24-hours"></i>+1 246 345 0695</h3>
											</div>
										</div>
										<form class="widgetCalcForm">
											<div class="row">
												<div class="col-xs-12">
													<div class="form-group">
														<input type="text" placeholder="Your Name" class="form-control">
													</div>
												</div>
												<div class="col-xs-12">
													<div class="form-group">
														<input type="tel" placeholder="Phone" class="form-control">
													</div>
												</div>
												<div class="col-xs-12">
													<div class="form-group">
														<input type="email" placeholder="Email" class="form-control">
													</div>
												</div>
											</div>
											<div class="form-group">
												<textarea class="form-control" placeholder="Hi Jennie Wilson, I saw your profile on LemanHouse and wanted to see if you could help me"></textarea>
											</div>
											<button type="submit" class="btn btnDark fontNeuron buttonXL">Submit</button>
										</form>
									</section>
									<!-- widgetCalc -->
									<section class="widget widgetPadding widgetCalc bgWhite">
										<h3 class="fontNeuron fwBold text-capitalize">Mortgage Calculator</h3>
										<!-- widgetCalcForm -->
										<form action="#" class="widgetCalcForm">
											<div class="form-group">
												<span class="icnInput small fas fa-dollar-sign"></span>
												<input type="text" class="form-control elemenBlock" placeholder="Property price">
											</div>
											<div class="form-group">
												<span class="icnInput small fas fa-dollar-sign"></span>
												<input type="text" class="form-control elemenBlock" placeholder="Deposit">
											</div>
											<div class="form-group">
												<span class="icnInput small fas fa-percent"></span>
												<input type="text" class="form-control elemenBlock" placeholder="Annual Interest">
											</div>
											<div class="form-group">
												<span class="icnInput small far fa-calendar-alt"></span>
												<input type="text" class="form-control elemenBlock" placeholder="Years">
											</div>
											<button type="submit" class="elemenBlock btn btnSecondary fontNeuron text-uppercase">CALCULATE</button>
											<p><span class="text-primary elemenBlock text-center textCount">Monthly Payment: $969.04</span></p>
										</form>
									</section>
									<!-- widgetRecentPosts -->
									<section class="widget widgetPadding widgetRecentPosts bgWhite">
										<h3 class="fontNeuron fwBold text-capitalize">Recent Properties</h3>
										<!-- widgetRecentPostsList -->
										<ul class="list-unstyled widgetRecentPostsList padlReset">
											<li>
												<div class="alignleft">
													<a href="properties-single1.html"><img src="https://via.placeholder.com/110x80" alt="image description"></a>
												</div>
												<div class="descrWrap">
													<h4 class="fontNeuron fwBold"><a href="properties-single1.html">Villa on Hollywood</a></h4>
													<strong class="price elemenBlock fwSemi fontNeuron">$ 920,000</strong>
													<p>778 Country St. Panama City, FL</p>
												</div>
											</li>
											<li>
												<div class="alignleft">
													<a href="properties-single1.html"><img src="https://via.placeholder.com/110x80" alt="image description"></a>
												</div>
												<div class="descrWrap">
													<h4 class="fontNeuron fwBold"><a href="properties-single1.html">4 Bedroom New House</a></h4>
													<strong class="price elemenBlock fwSemi fontNeuron">$ 467,000</strong>
													<p>London, United Kingdom</p>
												</div>
											</li>
											<li>
												<div class="alignleft">
													<a href="properties-single1.html"><img src="https://via.placeholder.com/110x80" alt="image description"></a>
												</div>
												<div class="descrWrap">
													<h4 class="fontNeuron fwBold"><a href="properties-single1.html">Eagle Apartments</a></h4>
													<strong class="price elemenBlock fwSemi fontNeuron">$ 467,000</strong>
													<p>9364 School St. Lynchburg, NY</p>
												</div>
											</li>
										</ul>
									</section>
								</aside>
							</div>
							<div class="col-xs-12">
								<header class="rowHead">
									<h1 class="fontNeuron blockH text-uppercase"><span class="bdrBottom">SIMILAR&nbsp;</span> <span class="textSecondary">&nbsp;PROPERTIES</span></h1>
								</header>
							</div>
							<div class="col-xs-12 col-sm-6 col-md-4 col isoCol sale">
								<!-- postColumn -->
								<article class="postColumn hasOver bgWhite">
									<div class="aligncenter">
										<!-- postColumnImageSlider -->
										<div class="slick-carousel slickSlider postColumnImageSlider">
											<div>
												<div class="imgHolder">
													<a href="properties-single1.html">
														<img src="https://via.placeholder.com/360x260" alt="image description">
													</a>
												</div>
											</div>
											<div>
												<div class="imgHolder">
													<a href="properties-single1.html">
														<img src="https://via.placeholder.com/360x260" alt="image description">
													</a>
												</div>
											</div>
											<div>
												<div class="imgHolder">
													<a href="properties-single1.html">
														<img src="https://via.placeholder.com/360x260" alt="image description">
													</a>
												</div>
											</div>
										</div>
										<!-- postHoverLinskList -->
										<ul class="list-unstyled postHoverLinskList">
											<li><a href="#"><i class="fi flaticon-repeat"></i></a></li>
											<li class="hasOver">
												<a href="#"><i class="fi flaticon-share"></i></a>
												<!-- postColumnSocial -->
												<ul class="list-unstyled socialNetworks postColumnSocial">
													<li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
													<li><a href="#"><i class="fab fa-twitter"></i></a></li>
													<li><a href="#"><i class="fab fa-instagram"></i></a></li>
													<li><a href="#"><i class="fab fa-google"></i></a></li>
												</ul>
											</li>
										</ul>
										<!-- linkToFavourite -->
										<a href="#" class="linkToFavourite roundedCircle bg-primary textWhite icnHeartBeatAnim"><i class="far fa-heart"></i></a>
									</div>
									<h2 class="fontNeuron text-capitalize"><a href="properties-single1.html">Luxury Family Home</a></h2>
									<address>
										<span class="icn"><i class="fi flaticon-pin-1"></i></span>
										<p>The Village, Jersey City, NJ 07302, USA </p>
									</address>
									<span class="btn btnSmall btn-success text-capitalize">For Sale</span>
									<h3 class="fontNeuron fwSemi"><span class="textSecondary">$ 490,000</span> <span class="textUnit fwNormal">/ monthly</span></h3>
									<!-- postColumnFoot -->
									<footer class="postColumnFoot">
										<ul class="list-unstyled">
											<li>
												<strong class="fwNormal elemenBlock text-primary">Area</strong>
												<strong class="fwNormal elemenBlock">2100 m2</strong>
											</li>
											<li>
												<strong class="fwNormal elemenBlock text-primary">Beds</strong>
												<strong class="fwNormal elemenBlock">3</strong>
											</li>
											<li>
												<strong class="fwNormal elemenBlock text-primary">Baths</strong>
												<strong class="fwNormal elemenBlock">2</strong>
											</li>
											<li>
												<strong class="fwNormal elemenBlock text-primary">Garages</strong>
												<strong class="fwNormal elemenBlock">1</strong>
											</li>
										</ul>
									</footer>
								</article>
							</div>
							<div class="col-xs-12 col-sm-6 col-md-4 col isoCol rent">
								<!-- postColumn -->
								<article class="postColumn hasOver bgWhite">
									<div class="aligncenter">
										<!-- postColumnImageSlider -->
										<div class="slick-carousel slickSlider postColumnImageSlider">
											<div>
												<div class="imgHolder">
													<a href="properties-single1.html">
														<img src="https://via.placeholder.com/360x260" alt="image description">
													</a>
												</div>
											</div>
											<div>
												<div class="imgHolder">
													<a href="properties-single1.html">
														<img src="https://via.placeholder.com/360x260" alt="image description">
													</a>
												</div>
											</div>
											<div>
												<div class="imgHolder">
													<a href="properties-single1.html">
														<img src="https://via.placeholder.com/360x260" alt="image description">
													</a>
												</div>
											</div>
										</div>
										<!-- postHoverLinskList -->
										<ul class="list-unstyled postHoverLinskList">
											<li><a href="#"><i class="fi flaticon-repeat"></i></a></li>
											<li class="hasOver">
												<a href="#"><i class="fi flaticon-share"></i></a>
												<!-- postColumnSocial -->
												<ul class="list-unstyled socialNetworks postColumnSocial">
													<li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
													<li><a href="#"><i class="fab fa-twitter"></i></a></li>
													<li><a href="#"><i class="fab fa-instagram"></i></a></li>
													<li><a href="#"><i class="fab fa-google"></i></a></li>
												</ul>
											</li>
										</ul>
										<!-- linkToFavourite -->
										<a href="#" class="linkToFavourite roundedCircle bg-primary textWhite icnHeartBeatAnim"><i class="far fa-heart"></i></a>
									</div>
									<h2 class="fontNeuron text-capitalize"><a href="properties-single1.html">Meridian Villas</a></h2>
									<address>
										<span class="icn"><i class="fi flaticon-pin-1"></i></span>
										<p>London, United Kingdom</p>
									</address>
									<span class="btn btnSmall btn-info text-capitalize">For Rent</span>
									<h3 class="fontNeuron fwSemi"><span class="textSecondary">$ 920,000</span> <span class="textUnit fwNormal">/ monthly</span></h3>
									<!-- postColumnFoot -->
									<footer class="postColumnFoot">
										<ul class="list-unstyled">
											<li>
												<strong class="fwNormal elemenBlock text-primary">Area</strong>
												<strong class="fwNormal elemenBlock">2100 m2</strong>
											</li>
											<li>
												<strong class="fwNormal elemenBlock text-primary">Beds</strong>
												<strong class="fwNormal elemenBlock">3</strong>
											</li>
											<li>
												<strong class="fwNormal elemenBlock text-primary">Baths</strong>
												<strong class="fwNormal elemenBlock">2</strong>
											</li>
											<li>
												<strong class="fwNormal elemenBlock text-primary">Garages</strong>
												<strong class="fwNormal elemenBlock">1</strong>
											</li>
										</ul>
									</footer>
								</article>
							</div>
							<div class="col-xs-12 col-sm-6 col-md-4 col isoCol rent">
								<!-- postColumn -->
								<article class="postColumn hasOver bgWhite">
									<div class="aligncenter">
										<!-- postColumnImageSlider -->
										<div class="slick-carousel slickSlider postColumnImageSlider">
											<div>
												<div class="imgHolder">
													<a href="properties-single1.html">
														<img src="https://via.placeholder.com/360x260" alt="image description">
													</a>
												</div>
											</div>
											<div>
												<div class="imgHolder">
													<a href="properties-single1.html">
														<img src="https://via.placeholder.com/360x260" alt="image description">
													</a>
												</div>
											</div>
											<div>
												<div class="imgHolder">
													<a href="properties-single1.html">
														<img src="https://via.placeholder.com/360x260" alt="image description">
													</a>
												</div>
											</div>
										</div>
										<!-- postHoverLinskList -->
										<ul class="list-unstyled postHoverLinskList">
											<li><a href="#"><i class="fi flaticon-repeat"></i></a></li>
											<li class="hasOver">
												<a href="#"><i class="fi flaticon-share"></i></a>
												<!-- postColumnSocial -->
												<ul class="list-unstyled socialNetworks postColumnSocial">
													<li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
													<li><a href="#"><i class="fab fa-twitter"></i></a></li>
													<li><a href="#"><i class="fab fa-instagram"></i></a></li>
													<li><a href="#"><i class="fab fa-google"></i></a></li>
												</ul>
											</li>
										</ul>
										<!-- linkToFavourite -->
										<a href="#" class="linkToFavourite roundedCircle bg-primary textWhite icnHeartBeatAnim"><i class="far fa-heart"></i></a>
									</div>
									<h2 class="fontNeuron text-capitalize"><a href="properties-single1.html">Elegant studio flat</a></h2>
									<address>
										<span class="icn"><i class="fi flaticon-pin-1"></i></span>
										<p>The Village, Jersey City, NJ 07302, USA</p>
									</address>
									<span class="btn btnSmall btn-info text-capitalize">For Rent</span>
									<h3 class="fontNeuron fwSemi"><span class="textSecondary">$ 490,000</span> <span class="textUnit fwNormal">/ monthly</span></h3>
									<!-- postColumnFoot -->
									<footer class="postColumnFoot">
										<ul class="list-unstyled">
											<li>
												<strong class="fwNormal elemenBlock text-primary">Area</strong>
												<strong class="fwNormal elemenBlock">2100 m2</strong>
											</li>
											<li>
												<strong class="fwNormal elemenBlock text-primary">Beds</strong>
												<strong class="fwNormal elemenBlock">3</strong>
											</li>
											<li>
												<strong class="fwNormal elemenBlock text-primary">Baths</strong>
												<strong class="fwNormal elemenBlock">2</strong>
											</li>
											<li>
												<strong class="fwNormal elemenBlock text-primary">Garages</strong>
												<strong class="fwNormal elemenBlock">1</strong>
											</li>
										</ul>
									</footer>
								</article>
							</div>
						</div>
					</div>
				</main>
		
</div>
<!-- pageFooterBlock -->
<app-footer></app-footer>
</div>
</div>