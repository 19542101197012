<!-- pageHeader -->
<header id="pageHeader" class="bgWhite offsetBottom">
  <!-- pageHeaderWrap -->
  <div class="pageHeaderWrap">
    <!-- headerTopBar -->
    <div class="bgLightDark headerTopBar textLightDark">
      <div class="container">
        <div class="row">
          <div class="col-xs-6 col-sm-4">
            <time datetime="2011-01-12" class="TopBarTime">Mon - Sat: 08.00 AM - 18.00 PM</time>
          </div>
          <div class="col-xs-6 col-sm-8 text-right">
            <!-- topBarPanelList -->
            <ul class="list-unstyled topBarPanelList">
              <li class="hidden-xs">
                <!-- topBarPanelListSocial -->
                <ul class="list-unstyled socialNetworks topBarPanelListSocial">
                  <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                  <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                  <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                  <li><a href="#"><i class="fab fa-google"></i></a></li>
                </ul>
              </li>
              <li>
                <!-- topBarPanelListDropdown -->
                <div class="dropdown topBarPanelListDropdown">
                  <button class="dropdown-toggle buttonReset" type="button" id="dropdownDol" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">USD ($)<span class="caret"></span></button>
                  <!-- dropdownDol -->
                  <ul class="dropdown-menu" aria-labelledby="dropdownDol">
                    <li><a href="#">USD</a></li>
                    <li><a href="#">EURO</a></li>
                    <li><a href="#">YTL</a></li>
                  </ul>
                </div>
              </li>
              <li>
                <!-- topBarPanelListDropdown -->
                <div class="dropdown topBarPanelListDropdown">
                  <button class="dropdown-toggle buttonReset" type="button" id="dropdownLang" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">English<span class="caret"></span></button>
                  <!-- dropdownLang -->
                  <ul class="dropdown-menu" aria-labelledby="dropdownLang">
                    <li><a href="#">English</a></li>
                    <li><a href="#">Turkish</a></li>
                    <li><a href="#">French</a></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <!-- headerHolder -->
      <div class="headerHolder">
        <div class="row">
          <div class="col-xs-6 col-sm-3">
            <!-- logo -->
            <div class="logo"><a routerLink="/home"><img src="assets/images/logo1.png" alt="whizBlock"></a></div>
          </div>
          <div class="col-xs-6 col-sm-9 d-flex">
            <!-- headerContactList -->
            <ul class="list-unstyled headerContactList">
              <li>
                <a href="tel:+12463450695" class="icn icnBubble noShrink text-info"><i class="fi flaticon-24-hours"></i></a>
                <div class="descr hidden-xs">
                  <strong class="fwNormal elemenBlock text"><a href="tel:+12463450695">+1 246-345-0695</a></strong>
                  <strong class="fwNormal elemenBlock text"><a
                      href="mailto:&#105;&#110;&#102;&#111;&#064;&#104;&#111;&#109;&#101;&#115;&#119;&#101;&#101;&#116;&#046;&#099;&#111;&#109;">&#105;&#110;&#102;&#111;&#064;&#104;&#111;&#109;&#101;&#115;&#119;&#101;&#101;&#116;&#046;&#099;&#111;&#109;</a></strong>
                </div>
              </li>
              <li class="hidden-xs">
                <span class="icn icnJumping text-info noShrink"><i class="fi flaticon-pin-1"></i></span>
                <div class="descr">
                  <strong class="fwNormal elemenBlock text">PO Box 16122 Collins Street West <br>Victoria 8007
                    Australia</strong>
                </div>
              </li>
            </ul>
            <!-- headerSearchForm -->
            <form action="#" class="headerSearchForm">
              <a class="headerSearchOpener roundedCircle bgWhite" role="button" data-toggle="collapse" href="#headerSearchFormcollapse" aria-expanded="false" aria-controls="headerSearchFormcollapse"><i class="fi flaticon-search btnOpen"></i><i
                  class="fas fa-times btnClose"></i></a>
              <!-- headerSearchFormcollapse -->
              <div class="collapse searchFormcollapse bgWhite" id="headerSearchFormcollapse" aria-expanded="false" role="form">
                <div class="form-group form-group-lg">
                  <label for="s" class="labelIcn"><i class="fi flaticon-search"></i></label>
                  <input type="search" id="s" class="form-control elemenBlock" placeholder="Search&hellip;" >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- pageNav -->
      <nav id="pageNav" class="navbar navbar-default pageNav1">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header hidden-sm hidden-md hidden-lg">
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
        </div>
        <!-- navbar collapse -->
        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <div class="navigation-wrapper">
            <strong class="h elemenBlock h4 textWhite text-center hidden-sm hidden-md hidden-lg menuTitle fontNeuron" id="menu-title">Menu</strong>
            <!-- pageMainNav -->
            <ul class="nav navbar-nav pageMainNav pageMainNav1">
              <li class="active dropdown">
                <a href="#" class="dropdown-toggle">Home<span class="caret"></span></a>
                <div class="frame-wrap">
                  <div class="frame">
                    <ul class="dropdown-menu pageMainNavDropdown pageMainNavDropdown1">
                      <li><a href="home.html">Homepage 1</a></li>
                      <li><a href="home2.html">Homepage 2</a></li>
                      <li><a href="home3.html">Homepage 3</a></li>
                      <li><a href="home4.html">Homepage 4</a></li>
                      <li><a href="home5.html">Homepage 5</a></li>
                      <li><a href="home6.html">Homepage 6</a></li>
                    </ul>
                  </div>
                </div>
              </li>
              <!-- remove dropdownFull class when its just regular dropdown -->
              <li class="dropdown dropdownFull">
                <a href="#" class="dropdown-toggle">Properties <span class="caret"></span></a>
                <div class="frame-wrap">
                  <div class="frame">
                    <ul class="dropdown-menu pageMainNavDropdown pageMainNavDropdown1 pageMainNavDropdownFull pageMainNavDropdownFull1">
                      <li>
                        <div class="dropdownColumnsHolder">
                          <div class="dropdownCol">
                            <strong class="fontNeuron elemenBlock dropdownTitle text-uppercase hidden-xs">Properties</strong>
                            <ul class="list-unstyled dropdownColList">
                              <li><a href="properties-grid.html">Properties Grid</a></li>
                              <li><a href="properties-list.html">Properties List</a></li>
                              <li><a href="properties-map-grid.html">Properties Map Grid</a></li>
                              <li><a href="properties-map-list.html">Properties Map List</a></li>
                              <li><a href="properties-grid-left-search.html">Properties Grid Left</a></li>
                              <li><a href="properties-half-map-grid.html">Properties Half Map Grid</a></li>
                              <li><a href="properties-half-map-list.html">Properties Half Map List</a></li>
                              <li><a href="properties-full-width-list.html">Properties Full Width List</a></li>
                              <li><a href="properties-full-width-grid.html">Properties Full Width Grid</a></li>
                            </ul>
                          </div>
                          <div class="dropdownCol">
                            <strong class="fontNeuron elemenBlock dropdownTitle text-uppercase hidden-xs">Properties
                              Single</strong>
                            <ul class="list-unstyled dropdownColList">
                              <li><a href="properties-single1.html">Properties Single 1</a></li>
                              <li><a href="properties-single2.html">Properties Single 2</a></li>
                              <li><a href="properties-single3.html">Properties Single 3</a></li>
                              <li><a href="properties-single4.html">Properties Single 4</a></li>
                              <li><a href="properties-single5.html">Properties Single 5</a></li>
                            </ul>
                          </div>
                          <div class="dropdownCol">
                            <strong class="fontNeuron elemenBlock dropdownTitle text-uppercase hidden-xs">New
                              Properties</strong>
                            <ul class="list-unstyled dropdownColList">
                              <li><a href="add-step-01.html">Add step 01</a></li>
                              <li><a href="add-step-02.html">Add step 02</a></li>
                              <li><a href="add-step-03.html">Add step 03</a></li>
                              <li><a href="add-step-04.html">Add step 04</a></li>
                              <li><a href="add-step-05.html">Add step 05</a></li>
                            </ul>
                          </div>
                          <div class="dropdownCol">
                            <strong class="fontNeuron elemenBlock dropdownTitle text-uppercase hidden-xs">Other
                              Pages</strong>
                            <ul class="list-unstyled dropdownColList">
                              <li><a href="user-profile.html">User Profile</a></li>
                              <li><a href="my-properties.html">My Properties</a></li>
                              <li><a href="favourite-properties.html">Favourite Properties</a></li>
                              <li><a href="membership.html">Membership</a></li>
                              <li><a href="properties-faqs.html">Properties Faqs</a></li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li class="dropdown">
                <a href="#" class="dropdown-toggle">Pages<span class="caret"></span></a>
                <div class="frame-wrap">
                  <div class="frame">
                    <ul class="dropdown-menu pageMainNavDropdown pageMainNavDropdown1">
                      <li><a href="404.html">404</a></li>
                      <li><a href="about.html">About</a></li>
                      <li><a href="agencies.html">Agencies</a></li>
                      <li><a href="agencies-detail.html">Agencies detail</a></li>
                      <li><a href="agent.html">Agent</a></li>
                      <li><a href="agent-detail.html">Agent detail</a></li>
                    </ul>
                  </div>
                </div>
              </li>
              <li class="dropdown">
                <a href="#" class="dropdown-toggle">Blog<span class="caret"></span></a>
                <div class="frame-wrap">
                  <div class="frame">
                    <ul class="dropdown-menu pageMainNavDropdown pageMainNavDropdown1">
                      <li><a href="blog.html">Blog</a></li>
                      <li><a href="blog2.html">Blog 2</a></li>
                      <li><a href="blog-detail.html">Blog detail</a></li>
                      <li><a href="blog-list.html">Blog list</a></li>
                      <li><a href="blog-full-width.html">Blog full width</a></li>
                      <li><a href="blog-list-full-width.html">Blog list full width</a></li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <a href="contact.html">Contact</a>
              </li>
            </ul>
          </div>
        </div>
        <!-- userOptions -->
        <div class="userOptions">
          <!-- UserLinksList -->
          <ul class="list-unstyled UserLinksList" *ngIf="isUserLoggedIn != 'yes'; else logoutOption">
            <li>
              <a href="#popup1" class="lightbox">
                <i class="fi flaticon-social icn"></i>
                <strong class="text fwNormal hidden-xs hidden-sm">Login</strong>
              </a>
            </li>
            <li>
              <a href="#popup1" class="lightbox">
                <i class="fi flaticon-edit icn"></i>
                <strong class="text fwNormal hidden-xs hidden-sm">Register</strong>
              </a>
            </li>
          </ul>
          <ng-template #logoutOption>
            <ul class="list-unstyled UserLinksList">
              <li>
                <span class="lightbox cursorPointer" (click)="logoutUser()">
                  <i class="fa fa-sign-out-alt"></i>
                  <strong class="text fwNormal hidden-xs hidden-sm">Logout<img *ngIf="isLoadingLogout" class="loadingImgLogout" src="assets/images/loading.gif" alt=""></strong>
                </span>
              </li>
            </ul>
          </ng-template>
          <!-- headerModalOpener -->
          <a href="#" class="headerModalOpener text-uppercase fontNeuron fwBold"><i class="openerIcon"></i> Submit
            Property</a>
        </div>
      </nav>
    </div>
  </div>
</header>

<div class="popup-holder">
  <div id="popup1" class="lightbox-demo">
    <!-- popupLoginFrom -->
    <form action="#" class="popupHolderWrap bgLightDark center-block popupLarge popupLoginFrom">
      <ul class="list-unstyled popupTabsetList text-center fwBold text-uppercase fontNeuron">
        <li class="active"><a href="#tab01">LOGIN</a></li>
        <li><a href="#tab02">REGISTER</a></li>
      </ul>
      <div class="tab-content">
        <div id="tab01" class="tab-active">
          <div class="popupColsHolder">
            <div class="col bgWhite">
              <form [formGroup]="loginForm" (submit)="loginForm.valid && loginUser()">
                <div class="form-group">
                  <input type="text" class="form-control elemenBlock" placeholder="Email" formControlName="email">
                  <div class="invalid-feedback" *ngIf="loginForm.get('email').touched && loginForm.get('email').invalid">
                    <span class="text-danger" *ngIf="loginForm.get('email').errors.required">Enter email.</span>
                    <span class="text-danger" *ngIf="loginForm.get('email').errors.email">Email must be valid</span>
                  </div>
                </div>
                <div class="form-group">
                  <input type="password" class="form-control elemenBlock" placeholder="Password" formControlName="password">
                  <div class="invalid-feedback" *ngIf="loginForm.get('password').touched && loginForm.get('password').invalid">
                    <span class="text-danger" *ngIf="loginForm.get('password').errors.required">Enter password.</span>
                    <span class="text-danger" *ngIf="loginForm.get('password').hasError('minlength')">Minimum length should be 6.<br /></span>
                  </div>
                </div>
                <div>
                  <re-captcha name="captcha" required siteKey="{{SITE_KEY}}"  formControlName="reCaptcha"></re-captcha>
                  
                </div>
                <button type="submit" class="btn btnSecondary elemenBlock fontNeuron fwNormal text-uppercase btnSubmit" [disabled]="loginForm.invalid || isLoadingLogin">LOGIN<img *ngIf="isLoadingLogin" class="loadingImg"
                    src="assets/images/loading.gif" alt="loading"></button>
              </form>
              <a class="linkForget cursorPointer" (click)="isForgotPassword = !isForgotPassword">Forgot Password?</a>
            </div>
            <div class="col" *ngIf="isForgotPassword">
              <form [formGroup]="forgotPasswordForm" (submit)="forgotPasswordForm.valid && sendForgotPasswordLink()">
                <p class="text-center">Forgot Password</p>
                <div class="form-group">
                  <input type="email" class="form-control elemenBlock" placeholder="Email" formControlName="email">
                  <div class="invalid-feedback" *ngIf="forgotPasswordForm.get('email').touched && forgotPasswordForm.get('email').invalid">
                    <span class="text-danger" *ngIf="forgotPasswordForm.get('email').errors.required">Enter email.</span>
                    <span class="text-danger" *ngIf="forgotPasswordForm.get('email').errors.email">Email must be valid</span>
                  </div>
                </div>
                <button type="submit" class="btn btnSecondary elemenBlock fontNeuron fwNormal text-uppercase btnSubmit" [disabled]="forgotPasswordForm.invalid || isLoadingForgot">Submit<img *ngIf="isLoadingForgot" class="loadingImg"
                    src="assets/images/loading.gif" alt="loading"></button>
              </form>
              <!-- <p>You Can Login using your facebook Profile or Google account</p>
              <button type="button" class="btnSocial btnFb elemenBlock"><i class="fab fa-facebook-f btnIco"></i>
                Facebook Connect</button>
              <button type="button" class="btnSocial btnTw elemenBlock"><i class="fab fa-twitter btnIco"></i> Twitter
                Connect</button>
              <button type="button" class="btnSocial btnG elemenBlock"><i class="fab fa-google btnIco"></i> Google
                Connect</button> -->
            </div>
          </div>
        </div>
        <div id="tab02">
          <div class="popupColsHolder">
            <div class="col bgWhite">
              <form [formGroup]="registerForm" (submit)="registerForm.valid && registerUser()">
                <!-- <div class="form-group">
                  <input type="text" class="form-control elemenBlock" placeholder="User Name" formControlName="userName">
                  <div class="invalid-feedback" *ngIf="registerForm.get('userName').touched && registerForm.get('userName').invalid">
                    <span class="text-danger" *ngIf="registerForm.get('userName').errors.required">Enter user name.</span>
                  </div>
                </div> -->
                <div class="form-group">
                  <input type="email" class="form-control elemenBlock" placeholder="Email Address" formControlName="email">
                  <div class="invalid-feedback" *ngIf="registerForm.get('email').touched && registerForm.get('email').invalid">
                    <span class="text-danger" *ngIf="registerForm.get('email').errors.required">Enter Username</span>
                    <span class="text-danger" *ngIf="registerForm.get('email').errors.email">Email must be valid</span>
                  </div>
                </div>
                <div class="form-group">
                  <input type="password" class="form-control elemenBlock" placeholder="Password" formControlName="password">
                  <div class="invalid-feedback" *ngIf="registerForm.get('password').touched && registerForm.get('password').invalid">
                    <span class="text-danger" *ngIf="registerForm.get('password').errors.required">Enter password.</span>
                    <span class="text-danger" *ngIf="registerForm.get('password').hasError('minlength')">Minimum length should be 6.<br /></span>
                  </div>
                </div>
                <div class="form-group">
                  <input type="password" class="form-control elemenBlock" placeholder="Confirm Password" formControlName="confirmPassword">
                  <div class="invalid-feedback" *ngIf="registerForm.get('confirmPassword').touched && registerForm.get('confirmPassword').invalid">
                    <span class="text-danger" *ngIf="registerForm.get('confirmPassword').errors.required">Enter confirm password.</span>
                    <span class="text-danger" *ngIf="registerForm.get('confirmPassword').errors.ConfirmPassword">Password not matched</span>
                  </div>
                </div>
                <div class="form-group">
                  <label class="fwNormal customLabelCheck">
                    <input type="checkbox" class="customFormInputReset" checked formControlName="checkField">
                    <span class="fakeCheckbox"></span>
                    <span class="fakeLabel">I have read and agree to the Term of Service.</span>
                  </label>
                  <div class="invalid-feedback">
                    <span class="text-danger" *ngIf="registerForm.hasError('required', 'checkField')">Please check above box to continue.</span>
                  </div>
                </div>
                <div class="mb-2">
                  <re-captcha name="captcha" required siteKey="{{SITE_KEY}}" formControlName="reCaptcha"></re-captcha>
                </div>
                <button type="submit" class="btn btn-primary elemenBlock fontNeuron fwNormal text-uppercase btnSubmit" [disabled]="registerForm.invalid || isLoadingRegister">REGISTER <img *ngIf="isLoadingRegister" class="loadingImg"
                    src="assets/images/loading.gif" alt=""></button>
              </form>
            </div>
            <!-- <div class="col">
              <p>You Can Login using your facebook Profile or Google account</p>
              <button type="button" class="btnSocial btnFb elemenBlock"><i class="fab fa-facebook-f btnIco"></i>
                Facebook Connect</button>
              <button type="button" class="btnSocial btnTw elemenBlock"><i class="fab fa-twitter btnIco"></i> Twitter
                Connect</button>
              <button type="button" class="btnSocial btnG elemenBlock"><i class="fab fa-google btnIco"></i> Google
                Connect</button>
            </div> -->
          </div>
        </div>
      </div>
    </form>
  </div>
</div>