import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ChangeUserProfileComponent } from './components/change-user-profile/change-user-profile.component';

import { FilestackModule } from '@filestack/angular';
import { ListPackagesComponent } from './list-packages/list-packages.component';
import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module
import { LatestPackageListComponent } from './latest-package-list/latest-package-list.component';
import { PremiumPackageDetailComponent } from './premium-package-detail/premium-package-detail.component';
import { LatestPackageDetailComponent } from './latest-package-detail/latest-package-detail.component';
import { SearchPipe } from './components/search.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgpSortModule } from "ngp-sort-pipe";
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
@NgModule({
  declarations: [ 
    AppComponent,
    HomeComponent,
    UserProfileComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    ChangeUserProfileComponent,
    ListPackagesComponent,
    LatestPackageListComponent,
    PremiumPackageDetailComponent,
    LatestPackageDetailComponent,
    SearchPipe,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    FilestackModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ToastrModule.forRoot(),
    NgxPaginationModule,
    Ng2SearchPipeModule,
    NgpSortModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
