<!-- pageWrapper -->
<div id="pageWrapper">
  <!-- pageMenuPushWrap -->
  <div class="pageMenuPushWrap pageMenuPushWrap1">
    <!-- bgBaseWrap -->
    <div class="bgBaseWrap">
      <!-- pageHeader -->
      <app-header></app-header>
      <!-- main -->
      <main>
        <!-- introSlider -->
        <section class="slick-fade slickSlider introSlider">
          <div>
            <!-- introSlide -->
            <article class="introSlide bgCover" style="background-image: url(https://via.placeholder.com/1920x100)">
              <div class="container introSlideHolder">
                <div class="align"               
            >
                  <!-- introSlideCaption -->
                  
                </div>
              </div>
            </article>
          </div>
         
          <div>
            <!-- introSlide -->
            <article class="introSlide bgCover" style="background-image: url(https://via.placeholder.com/1920x750);">
              <div class="container introSlideHolder">
                <div class="align">
                 
                </div>
              </div>
            </article>
          </div>
        </section>
         
        <!-- latestPostsBlock -->
        <section class="latestPostsBlock container">
          <!-- rowHead -->
          <header class="row rowHead">
            <div class="col-xs-12 col-sm-5">
              <h1 class="fontNeuron blockH text-uppercase"><span class="bdrBottom">PREMIUM&nbsp;</span> <span class="textSecondary">PACKAGE</span></h1>
            </div>
          <div class="col-xs-12 col-sm-7">
            </div>
          </header>
          <!-- isoContentHolder -->
          <div class="isoContentHolder">
              <!-- <div *ngIf="rowData.length>1"> *ngFor="let item of rowData |slice:0:2; let i=index" -->
            <div class="row" >
              <div class="col-xs-12 col-sm-6 col-md-4 col isoCol sale">
                <!-- postColumn -->
                <article class="postColumn hasOver bgWhite">
                  <div class="aligncenter">
                    <!-- postColumnImageSlider -->
                    <div class="slick-carousel slickSlider postColumnImageSlider">
                      <div>
                        <div class="imgHolder">
                          <a href="properties-single1.html">
                            <img src="https://via.placeholder.com/360x260" alt="image description">
                          </a>
                        </div>
                      </div>
                      <div>
                        <div class="imgHolder">
                          <a href="properties-single1.html">
                            <img src="https://via.placeholder.com/360x260" alt="image description">
                          </a>
                        </div>
                      </div>
                      <div>
                        <div class="imgHolder">
                          <a href="properties-single1.html">
                            <img src="https://via.placeholder.com/360x260" alt="image description">
                          </a>
                        </div>
                      </div>
                    </div>
                    <!-- postHoverLinskList -->
                    <ul class="list-unstyled postHoverLinskList">
                      <li><a href="#"><i class="fi flaticon-repeat"></i></a></li>
                      <li class="hasOver">
                        <a href="#"><i class="fi flaticon-share"></i></a>
                        <!-- postColumnSocial -->
                        <ul class="list-unstyled socialNetworks postColumnSocial">
                          <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                          <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                          <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                          <li><a href="#"><i class="fab fa-google"></i></a></li>
                        </ul>
                      </li>
                    </ul>
                    <!-- linkToFavourite -->
                    <a href="#" class="linkToFavourite roundedCircle bg-primary textWhite icnHeartBeatAnim"><i class="far fa-heart"></i></a>
                  </div>
                  <h2 class="fontNeuron text-capitalize" ><a routerLink="/premium-package-detail"></a></h2>
                  <address>
                    <span class="icn"><i class="fi flaticon-pin-1"></i></span>
                    <p>The Village, Jersey City, NJ 07302, USA </p>
                  </address>
                  <span class="btn btnSmall btn-success text-capitalize">For Sale</span>
                  <h3 class="fontNeuron fwSemi"><span class="textSecondary">$ 490,000</span> <span class="textUnit fwNormal">/ monthly</span></h3>
                  <!-- postColumnFoot -->
                  <footer class="postColumnFoot">
                    <ul class="list-unstyled">
                      <li>
                        <strong class="fwNormal elemenBlock text-primary">Area</strong>
                        <strong class="fwNormal elemenBlock">2100 m2</strong>
                      </li>
                      <li>
                        <strong class="fwNormal elemenBlock text-primary">Beds</strong>
                        <strong class="fwNormal elemenBlock">3</strong>
                      </li>
                      <li>
                        <strong class="fwNormal elemenBlock text-primary">Baths</strong>
                        <strong class="fwNormal elemenBlock">2</strong>
                      </li>
                      <li>
                        <strong class="fwNormal elemenBlock text-primary">Garages</strong>
                        <strong class="fwNormal elemenBlock">1</strong>
                      </li>
                    </ul>
                  </footer>
                </article>
              </div>
            </div>
              <!-- </div> -->
          </div>
          <div class="row text-center btnHolder">
            <a href="properties-detial.html" class="btn btn-primary btnPrimaryOutline text-capitalize fontNeuron">Show
              More Package</a>
          </div>
        </section>
        
         <!-- latestPostsBlock -->
         <section class="latestPostsBlock container">
          <!-- rowHead -->
          <br/>
          <header class="row rowHead">
            <div class="col-xs-12 col-sm-5">
              <h1 class="fontNeuron blockH text-uppercase"><span class="bdrBottom">LATEST&nbsp; </span> <span class="textSecondary">PACKAGE</span></h1>
            </div>          
          </header>
          <!-- isoContentHolder -->
          <div class="isoContentHolder">
            <div class="row" >
              <div class="col-xs-12 col-sm-6 col-md-4 col isoCol sale">
                <!-- postColumn -->
                <article class="postColumn hasOver bgWhite">
                  <div class="aligncenter">
                    <!-- postColumnImageSlider -->
                    <div class="slick-carousel slickSlider postColumnImageSlider">
                      <div>
                        <div class="imgHolder">
                          <a href="properties-single1.html">
                            <img src="https://via.placeholder.com/360x260" alt="image description">
                          </a>
                        </div>
                      </div>
                      <div>
                        <div class="imgHolder">
                          <a href="properties-single1.html">
                            <img src="https://via.placeholder.com/360x260" alt="image description">
                          </a>
                        </div>
                      </div>
                      <div>
                        <div class="imgHolder">
                          <a href="properties-single1.html">
                            <img src="https://via.placeholder.com/360x260" alt="image description">
                          </a>
                        </div>
                      </div>
                    </div>
                    <!-- postHoverLinskList -->
                    <ul class="list-unstyled postHoverLinskList">
                      <li><a href="#"><i class="fi flaticon-repeat"></i></a></li>
                      <li class="hasOver">
                        <a href="#"><i class="fi flaticon-share"></i></a>
                        <!-- postColumnSocial -->
                        <ul class="list-unstyled socialNetworks postColumnSocial">
                          <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                          <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                          <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                          <li><a href="#"><i class="fab fa-google"></i></a></li>
                        </ul>
                      </li>
                    </ul>
                    <!-- linkToFavourite -->
                    <a href="#" class="linkToFavourite roundedCircle bg-primary textWhite icnHeartBeatAnim"><i class="far fa-heart"></i></a>
                  </div>
                  <h2 class="fontNeuron text-capitalize"><a routerLink="/latest-package-detail"></a></h2>
                  <address>
                    <span class="icn"><i class="fi flaticon-pin-1"></i></span>
                    <p>The Village, Jersey City, NJ 07302, USA </p>
                  </address>
                  <span class="btn btnSmall btn-success text-capitalize">For Sale</span>
                  <h3 class="fontNeuron fwSemi"><span class="textSecondary">$ 490,000</span> <span class="textUnit fwNormal">/ monthly</span></h3>
                  <!-- postColumnFoot -->
                  <footer class="postColumnFoot">
                    <ul class="list-unstyled">
                      <li>
                        <strong class="fwNormal elemenBlock text-primary">Area</strong>
                        <strong class="fwNormal elemenBlock">2100 m2</strong>
                      </li>
                      <li>
                        <strong class="fwNormal elemenBlock text-primary">Beds</strong>
                        <strong class="fwNormal elemenBlock">3</strong>
                      </li>
                      <li>
                        <strong class="fwNormal elemenBlock text-primary">Baths</strong>
                        <strong class="fwNormal elemenBlock">2</strong>
                      </li>
                      <li>
                        <strong class="fwNormal elemenBlock text-primary">Garages</strong>
                        <strong class="fwNormal elemenBlock">1</strong>
                      </li>
                    </ul>
                  </footer>
                </article>
              </div>
             
            </div>
          </div>
          <div class="row text-center btnHolder">
            <a href="properties-detial.html" class="btn btn-primary btnPrimaryOutline text-capitalize fontNeuron">Show
              More Package</a>
          </div>
        </section>
        
        <!-- sponsorsBlock -->
        <section class="container sponsorsBlock">
          <h1 class="fontNeuron blockH text-uppercase"><span class="bdrBottom">OUR&nbsp;</span> <span class="textSecondary">PARTNERS</span></h1>
          <!-- LogosCarousel -->
          <div class="logos-slider slickSlider LogosCarousel">
            <div>
              <!-- logoColumn -->
              <div class="logoColumn">
                <a href="#">
                  <img src="assets/images/sponsor01.png" alt="helsinki">
                </a>
              </div>
            </div>
            <div>
              <!-- logoColumn -->
              <div class="logoColumn">
                <a href="#">
                  <img src="assets/images/sponsor02.png" alt="norway">
                </a>
              </div>
            </div>
            <div>
              <!-- logoColumn -->
              <div class="logoColumn">
                <a href="#">
                  <img src="assets/images/sponsor03.png" alt="lars grondahl">
                </a>
              </div>
            </div>
            <div>
              <!-- logoColumn -->
              <div class="logoColumn">
                <a href="#">
                  <img src="assets/images/sponsor04.png" alt="kirsten">
                </a>
              </div>
            </div>
            <div>
              <!-- logoColumn -->
              <div class="logoColumn">
                <a href="#">
                  <img src="assets/images/sponsor05.png" alt="only oslo">
                </a>
              </div>
            </div>
            <div>
              <!-- logoColumn -->
              <div class="logoColumn">
                <a href="#">
                  <img src="assets/images/sponsor02.png" alt="norway">
                </a>
              </div>
            </div>
            <div>
              <!-- logoColumn -->
              <div class="logoColumn">
                <a href="#">
                  <img src="assets/images/sponsor03.png" alt="lars grondahl">
                </a>
              </div>
            </div>
            <div>
              <!-- logoColumn -->
              <div class="logoColumn">
                <a href="#">
                  <img src="assets/images/sponsor04.png" alt="kirsten">
                </a>
              </div>
            </div>
          </div>
        </section>
      
        <!-- signupFormAside -->
        <form action="#" class="bgWhite signupFormAside">
          <div class="container signupFormAsideHolder">
            <span class="icnAbsolute elemenBlock fi flaticon-message"></span>
            <div class="col-xs-12 col-md-offset-2 col-md-6">
              <h3 class="fontNeuron textSecondary">Sign up for our newsletter.</h3>
              <p>Lorem molestie odio. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
            </div>
            <div class="col-xs-12 col-md-4">
              <div class="form-group">
                <input type="email" id="email" class="form-control elemenBlock" placeholder="Your email">
                <button type="button" class="buttonReset fi flaticon-navigation"></button>
              </div>
            </div>
          </div>
        </form>
      </main>
    </div>
    <!-- pageFooterBlock -->
    <app-footer></app-footer>
  </div>
</div>