import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  // set current user loggedIn data
  setLoggedInUserData(data) {
    localStorage.setItem('currentUserData', JSON.stringify(data));
  }

  // retreive current user loggedIn data
  getLoggedInUserData() {
    return JSON.parse(localStorage.getItem('currentUserData'));
  }

  // set userLoggedIn value to true
  setUserLoggedIn() {
    localStorage.setItem('userLoggedIn', 'true');
  }

  setUserLoggedInFalse() {
    localStorage.setItem('userLoggedIn', 'false');
  }

  // retreive userLoggedIn value
  getUserLoggedIn() {
    return localStorage.getItem('userLoggedIn');
  }

  // start login session
  startSession() {
    localStorage.setItem('sts', (Date.now() + 600000).toString())
  }

  getSession() {
    return parseInt(localStorage.getItem('sts'));
  }

  // clear all local storage
  clearLocalStorage() {
    localStorage.clear();
  }
}
