import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastrService } from "ngx-toastr";
import { LocalStorageService } from "../../services/localStorage/local-storage.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private fb: FormBuilder, private authService: AuthService, private toastr: ToastrService, private localStorage: LocalStorageService,
    private router: Router
  ) { }
      // SITE_KEY='6LchdfkUAAAAAD9xwlUUo16dNY3t8u9EABI8Iq3S';
    // SITE_KEY = '6LcOuyYTAAAAAHTjFuqhA52fmfJ_j5iFk5PsfXaU';
     //SITE_KEY='6Ldm8foUAAAAAJgZ4Ye2Bt_NFgmbHq9S5PWIn7RY';
     SITE_KEY='6Lfy8_oUAAAAAEXMm8apVG9J8-yOpctiQMEVt6Dy';
  isUserLoggedIn = '';

  // toggle forgot section
  isForgotPassword = false;
 
  // loading initially false
  isLoadingRegister = false;
  isLoadingLogin = false;
  isLoadingForgot = false;
  isLoadingLogout = false;

  //success messages
  successRegisterMsg = '';

  // register form group
  registerForm = this.fb.group({
    // userName: ['', Validators.required],
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    checkField: [true, Validators.requiredTrue],
    reCaptcha: [null, Validators.required]
  }, { validator: this.matchPassword });

  // login form group
  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    reCaptcha: ['', Validators.required]
   
  });

  // forgot password form group
  forgotPasswordForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]]
  });

  ngOnInit(): void {
    console.log('user pesrmission', this.authService.userPermission.value);
    // set userPermission value to this component's variable
    this.isUserLoggedIn = this.authService.userPermission.value;
    console.log('isUserLoggedIn', this.isUserLoggedIn);

  }

  // match password and confirm password while registering the user
  matchPassword(control: AbstractControl) {
    let password = control.get('password').value;
    let confirmPassword = control.get('confirmPassword').value;

    if (password != confirmPassword) {
      control.get('confirmPassword').setErrors({ ConfirmPassword: true });
    }
    else {
      return null;
    }
  }

  // register user
 registerUser() {
    console.log('register user', this.registerForm.value);
    this.isLoadingRegister = true;
    const obj = {
      "email": this.registerForm.get('email').value,
      "password": this.registerForm.get('password').value,
      "googleRecaptcha": this.registerForm.get('reCaptcha').value

    }
    console.log('register user', obj);

    this.authService.registerUser(obj).subscribe((registerationRes: any) => {
      this.isLoadingRegister = false;

      console.log('register response', registerationRes);
      if (registerationRes.error === '' || registerationRes.error === undefined) {
        this.toastr.success('Check your email for account activation.', 'User Registered Successfully.', {
          disableTimeOut: true,
          tapToDismiss: false,
          closeButton: true
        });
        // this.successRegisterMsg = 'Check your email for account activation.'
      } else {
        this.toastr.error(registerationRes.error['Error Description'], registerationRes.error['Error Msg'], {
          disableTimeOut: true,
          tapToDismiss: false,
          closeButton: true
        });
      }
    });
  } 


  // login user
  loginUser() {
    this.isLoadingLogin = true;
debugger
    const obj = {
      email: this.loginForm.get('email').value,
      password: this.loginForm.get('password').value,
      googleRecaptcha: this.loginForm.get('reCaptcha').value

    }

    console.log('login user', obj);

    // login api calling
    this.authService.loginUser(obj).subscribe((loginRes: any) => {
      this.isLoadingLogin = false;

      console.log('login res', loginRes);
      if (loginRes.error === '' || loginRes.error === undefined) {
        
        // show toastr message
        this.toastr.success('', 'User LoggedIn Successfully.', {
          tapToDismiss: false,
          closeButton: true
        });
        //set
          this.authService.getCurrentUserId=loginRes.idUser;

        // set behaviourSubject value after login
        this.authService.afterLogin();


        // set current user data in local storage
        this.localStorage.setLoggedInUserData(loginRes.data);

        // set userLoggedIn value in local storage
        this.localStorage.setUserLoggedIn();

        // start session after login
        this.localStorage.startSession();

        // reload page
        location.reload();
      } else {
        if (loginRes.error.ErrorNo === '6') {
          this.isLoadingLogin = true;
          console.log('want logout', loginRes.error.idUser);
          this.logOutPreviousSession(loginRes.error.idUser).then((result) => {
            console.log('prev session', result);
            if(result) {
              this.loginUser();
            }
          });

        } else {
          // show error message
          this.toastr.error(loginRes.error['Error Description'], loginRes.error['Error Msg'], {
            disableTimeOut: true,
            tapToDismiss: false,
            closeButton: true
          });
        }
      }
    })
  }

  // send forgot password link
  sendForgotPasswordLink() {
    this.isLoadingForgot = true;

    const obj = {
      email: this.forgotPasswordForm.get('email').value
    }

    console.log('send forgot link', obj);
    // this.toastr.success('Hello world!', 'Toastr fun!');
    this.authService.forgotUserPassword(obj).subscribe((forgotPassRes: any) => {
      console.log('forgot pass res', forgotPassRes);
      this.isLoadingForgot = false;
      if (forgotPassRes.error === '' || forgotPassRes.error === undefined) {
        this.toastr.success(forgotPassRes.data['Success Description'], forgotPassRes.data['Success Msg'], {
          disableTimeOut: true,
          tapToDismiss: false,
          closeButton: true
        });
      } else {
        this.toastr.error(forgotPassRes.error['Error Description'], forgotPassRes.error['Error Msg'], {
          disableTimeOut: true,
          tapToDismiss: false,
          closeButton: true
        });
      }
    })
  }

  async logOutPreviousSession(userId) {
    let currentUser = userId;

    const obj = {
      idUser: currentUser
    }
    console.log('user logout', currentUser);
    console.log('user logout', obj);

    return new Promise((resolve, reject) => {
      this.authService.logoutPreviousSession(obj).subscribe((logoutRes: any) => {
        console.log('logout res', logoutRes);
        if (logoutRes.error === '' || logoutRes.error === undefined) {
          this.localStorage.clearLocalStorage();
          resolve('done');
        } else {
          reject();
        }
      });
    });
  }

  logoutUser() {
    this.isLoadingLogout = true;
    const currentUser = this.localStorage.getLoggedInUserData();
    const obj = {
      idUser: currentUser.idUser,
      sessionKey: currentUser.Key.sessionKey,
      publicKey: currentUser.Key.publicKey,
      accessKey: ''
    }
    console.log('user logout', currentUser);
    console.log('user logout', obj);

    this.authService.logoutUser(obj).subscribe((logoutRes: any) => {
      console.log('logout res', logoutRes);
      if (logoutRes.error === '' || logoutRes.error === undefined) {
        this.localStorage.clearLocalStorage();
        location.reload();
      }

    });
  }
 
}
