import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastrService } from "ngx-toastr";
import { LocalStorageService } from "../../services/localStorage/local-storage.service";
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-change-user-profile',
  templateUrl: './change-user-profile.component.html',
  styleUrls: ['./change-user-profile.component.css']
})
export class ChangeUserProfileComponent implements OnInit {
  userInfoData:any;
  apikey: string;
  onSuccess;
  onError;
  userDetail:any;
  username:any;
  image:any;

  constructor(private fb: FormBuilder, private authService: AuthService, private toastr: ToastrService, private localStorage: LocalStorageService,
    private router: Router
  ) { }

  // update profile form group
  updateProfileForm = this.fb.group({
    userName:['', Validators.required],
    email: ['', [Validators.required]],
     mobile:['']   
  });
// password change form group
changePasswordForm = this.fb.group({
  password: ['', [Validators.required, Validators.required]],
  newPassword: ['', [Validators.required, Validators.minLength(6)]],
  confirmNewPassword: [null, Validators.required]
   }, { validator: this.MustMatch
});


  ngOnInit(): void {
    this.userDetail=this.localStorage.getLoggedInUserData();
    
   const obj={
    
      idUser: this.userDetail.idUser,
      publicKey: this.userDetail.Key.publicKey,
      sessionKey: this.userDetail.Key.sessionKey,
      accessKey: "",
      userType: this.userDetail.userType
    }
        //call user info api
    this.authService.userInfo(obj).subscribe((userInfoRes: any) => {
      this.userInfoData= userInfoRes.data;
    this.image= this.userInfoData.avatar;
    this.username=this.userInfoData.username;

      this.updateProfileForm.controls.userName.setValue( this.userInfoData.username);
      this.updateProfileForm.controls.email.setValue( this.userInfoData.email);
      this.updateProfileForm.controls.mobile.setValue( '+1 246-345-0695');
    });
    
    this.apikey = 'Af8ngQrhbTFydrIU0HEiUz';
    this.onSuccess = (res) => console.log('###onSuccess', res);

    this.onError = (err) => console.log('###onErr', err);

    
  }
  onUploadSuccess(res: any) {
    console.log('###uploadSuccess', res);
    this.userInfoData.avatar = res.filesUploaded[0].url;
    this.updateProfileimage();
    
    
  }

  onUploadError(err: any) {
    console.log('###uploadError', err);
  
  }
 
  //
  updateProfileimage()
  {
    const obj= {
      idUser: this.userInfoData.idUser,
      publicKey: this.userInfoData.Key.publicKey,
      sessionKey: this.userInfoData.Key.sessionKey,
      accessKey: '',
      image:this.userInfoData.avatar
  }
    this.authService.updateProfileimage(obj).subscribe((updateProfileimageRes: any) => {
     
      console.log('updateUserDetails response', updateProfileimageRes);
  
      if (updateProfileimageRes.error === '' || updateProfileimageRes.error === undefined) {
        this.toastr.success('', 'User profile image updated successfully', {
          disableTimeOut: true,
          tapToDismiss: false,
          closeButton: true
        });
        
      } else {
        this.toastr.error(updateProfileimageRes.error['Error Description'], updateProfileimageRes.error['Error Msg'], {
          disableTimeOut: true,
          tapToDismiss: false,
          closeButton: true
        });
      }
    });
  
  }
  // update user details
  updateUserDetails()
  {
    const objdata = {
      "userName": this.updateProfileForm.get('userName').value,
      "email": this.updateProfileForm.get('email').value,
      "mobile": this.updateProfileForm.get('mobile').value
    }
    console.log('update profile', objdata);

  const obj={

    idUser: this.userDetail.idUser,
    publicKey: this.userDetail.Key.publicKey,
    sessionKey: this.userDetail.Key.sessionKey,
    accessKey: '',
    userType: this.userDetail.userType,
    dateofBirth: '',
    firstName: objdata.userName,
    lastName: this.userDetail.lastName,
    gender: '',
    address1: '',
    address2: '',
    city: '',
    mobile:objdata.mobile,
    country: '',
    countryCode: '',
    zipCode: ''
  }
  console.log('update user details', obj);

  this.authService.updateUserDetails(obj).subscribe((updateUserDetailsRes: any) => {
    
    console.log('updateUserDetails response', updateUserDetailsRes);

    if (updateUserDetailsRes.error === '' || updateUserDetailsRes.error === undefined) {
      this.toastr.success('', 'User Details updated successfully', {
        disableTimeOut: true,
        tapToDismiss: false,
        closeButton: true
      });
      
    } else {
      this.toastr.error(updateUserDetailsRes.error['Error Description'], updateUserDetailsRes.error['Error Msg'], {
        disableTimeOut: true,
        tapToDismiss: false,
        closeButton: true
      });
    }
  });

  }

  // password change
  passwordChange()
  {
    const obj={
      idUser: this.userInfoData.idUser,
      accessKey: '',
      publicKey: this.userInfoData.Key.publicKey,
      sessionKey: this.userInfoData.Key.sessionKey,
      newPassword: this.changePasswordForm.get('newPassword').value,
      oldPassword:this.changePasswordForm.get('password').value
     
    }
    console.log('change password', obj);
     // call change password api
    this.authService.passwordChange(obj).subscribe((passwordChangeRes: any) => {
      //this.isLoadingChangePassword = false;

      console.log('password change response', passwordChangeRes);
      if (passwordChangeRes.error === '' || passwordChangeRes.error === undefined) {
        this.toastr.success('Password Change', 'User has changed the Password', {
          disableTimeOut: true,
          tapToDismiss: false,
          closeButton: true
        });
        
      } else {
        this.toastr.error(passwordChangeRes.error['Error Description'], passwordChangeRes.error['Error Msg'], {
          disableTimeOut: true,
          tapToDismiss: false,
          closeButton: true
        });
      }
    });
  }

  // custom validator to check that two fields match
  MustMatch(newPassword: string, confirmNewPassword: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[newPassword];
        const matchingControl = formGroup.controls[confirmNewPassword];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}
}
