import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from "../../services/localStorage/local-storage.service";
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  userInfoData:any;
  userName:any;
  userType:any;
  email:any;
  image:any;
  

  constructor(private authService: AuthService, private toastr: ToastrService, private localStorage: LocalStorageService) { }

  ngOnInit(): void {
    this.userInfoData=this.localStorage.getLoggedInUserData();
    console.log('user-info data', this.userInfoData);

   const obj={
      idUser: this.userInfoData.idUser,
      publicKey: this.userInfoData.Key.publicKey,
      sessionKey: this.userInfoData.Key.sessionKey,
      accessKey: "",
      userType: this.userInfoData.userType
    }
        //call user info api
    this.authService.userInfo(obj).subscribe((userInfoRes: any) => {
    
      console.log('user info res', userInfoRes);
      this.userName=userInfoRes.data.username;
      this.userType=userInfoRes.data.userType;
      this.email=userInfoRes.data.email;
       this.image=userInfoRes.data.avatar;
      
    })

  }
  
}
