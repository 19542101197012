import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from "../services/localStorage/local-storage.service";
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastrService } from "ngx-toastr";
//import { EventEmitter } from 'protractor';

@Component({
  selector: 'app-list-packages',
  templateUrl: './list-packages.component.html',
  styleUrls: ['./list-packages.component.css']
})
export class ListPackagesComponent implements OnInit {
  term: string;
  p: number = 1;
  perPageItem:number=4;
  userDetail:any;
  rowData: any;
  packageTitle:string;
 
   obj=[
    {
      "idPackage": "1",
      "idPackageType": 10,
      "packageTitle": "Package1",
      "packageSlug": "DIM-EUR",
      "idPairSource": "1",
      "idPairTarget": "2",
      "initialValue": "100",
      "lockupPeriod": 12,
      "releasedAt": "2020-10-29T00:00:00+00:00",
      "isPrimary": "1",
      "isActive": "1"
    },
    {
      "idPackage": "2",
      "idPackageType": 11,
      "packageTitle": "Package2",
      "packageSlug": "DIM-EUR",
      "idPairSource": "1",
      "idPairTarget": "2",
      "initialValue": "100",
      "lockupPeriod": 12,
      "releasedAt": "2020-10-29T00:00:00+00:00",
      "isPrimary": "1",
      "isActive": "1"
    },
    {
      "idPackage": "3",
      "idPackageType": 12,
      "packageTitle": "Package3",
      "packageSlug": "DIM-EUR",
      "idPairSource": "1",
      "idPairTarget": "2",
      "initialValue": "100",
      "lockupPeriod": 12,
      "releasedAt": "2020-10-29T00:00:00+00:00",
      "isPrimary": "1",
      "isActive": "1"
    },
    {
      "idPackage": "4",
      "idPackageType": 10,
      "packageTitle": "Package4",
      "packageSlug": "DIM-EUR4",
      "idPairSource": "1",
      "idPairTarget": "2",
      "initialValue": "100",
      "lockupPeriod": 12,
      "releasedAt": "2020-10-29T00:00:00+00:00",
      "isPrimary": "1",
      "isActive": "1"
    },
    {
      "idPackage": "5",
      "idPackageType": 11,
      "packageTitle": "Package5",
      "packageSlug": "DIM-EUR5",
      "idPairSource": "1",
      "idPairTarget": "2",
      "initialValue": "100",
      "lockupPeriod": 12,
      "releasedAt": "2020-10-29T00:00:00+00:00",
      "isPrimary": "1",
      "isActive": "1"
    },
    {
      "idPackage": "6",
      "idPackageType": 12,
      "packageTitle": "Package6",
      "packageSlug": "DIM-EU6",
      "idPairSource": "1",
      "idPairTarget": "2",
      "initialValue": "100",
      "lockupPeriod": 12,
      "releasedAt": "2020-10-29T00:00:00+00:00",
      "isPrimary": "1",
      "isActive": "1"
    },
    {
      "idPackage": "7",
      "idPackageType": 10,
      "packageTitle": "Package7",
      "packageSlug": "DIM-EUR7",
      "idPairSource": "1",
      "idPairTarget": "2",
      "initialValue": "100",
      "lockupPeriod": 12,
      "releasedAt": "2020-10-29T00:00:00+00:00",
      "isPrimary": "1",
      "isActive": "1"
    },
    {
      "idPackage": "8",
      "idPackageType": 11,
      "packageTitle": "Package8",
      "packageSlug": "DIM-EUR8",
      "idPairSource": "1",
      "idPairTarget": "2",
      "initialValue": "100",
      "lockupPeriod": 12,
      "releasedAt": "2020-10-29T00:00:00+00:00",
      "isPrimary": "1",
      "isActive": "1"
    },
    {
      "idPackage": "3",
      "idPackageType": 12,
      "packageTitle": "Package3",
      "packageSlug": "DIM-EUR",
      "idPairSource": "1",
      "idPairTarget": "2",
      "initialValue": "100",
      "lockupPeriod": 12,
      "releasedAt": "2020-10-29T00:00:00+00:00",
      "isPrimary": "1",
      "isActive": "1"
    },
    {
      "idPackage": "1",
      "idPackageType": 10,
      "packageTitle": "Package1",
      "packageSlug": "DIM-EUR",
      "idPairSource": "1",
      "idPairTarget": "2",
      "initialValue": "100",
      "lockupPeriod": 12,
      "releasedAt": "2020-10-29T00:00:00+00:00",
      "isPrimary": "1",
      "isActive": "1"
    },
    {
      "idPackage": "2",
      "idPackageType": 11,
      "packageTitle": "Package2",
      "packageSlug": "DIM-EUR",
      "idPairSource": "1",
      "idPairTarget": "2",
      "initialValue": "100",
      "lockupPeriod": 12,
      "releasedAt": "2020-10-29T00:00:00+00:00",
      "isPrimary": "1",
      "isActive": "1"
    },
    {
      "idPackage": "3",
      "idPackageType": 12,
      "packageTitle": "Package3",
      "packageSlug": "DIM-EUR",
      "idPairSource": "1",
      "idPairTarget": "2",
      "initialValue": "100",
      "lockupPeriod": 12,
      "releasedAt": "2020-10-29T00:00:00+00:00",
      "isPrimary": "1",
      "isActive": "1"
    },
  ];
  tempObj=[];
  

  constructor( private authService: AuthService, private toastr: ToastrService, private localStorage: LocalStorageService) { }

  ngOnInit(): void {
   
   this. userDetail=this.localStorage.getLoggedInUserData();
   
   this.tempObj = this.obj;
   const obj={
    idUser: this.userDetail.idUser,
    sessionKey: this.userDetail.Key.sessionKey,
    publicKey: this.userDetail.Key.publicKey,
    accessKey: '',
    page: '2',
    limit: '4',
    search: ''
    }
  console.log('list package api param', obj);

  this.authService.listPackage(obj)
    .subscribe(listPackageRes=>{ 
      this.rowData=listPackageRes; 
      console.log( 'rowdata',this.rowData);
    console.log('list package response', listPackageRes);
    this.rowData=listPackageRes;
    
  });
 
  }
 
     
  changePerPage(value){
    debugger
   this.perPageItem =  parseInt(value)
  }

  listPackage () {
    
    const obj={
      idUser: this.userDetail.idUser,
      sessionKey: this.userDetail.Key.sessionKey,
      publicKey: this.userDetail.Key.publicKey,
      accessKey: '',
      page: '',
      limit: '',
      search: ''
      }
    console.log('list package api param', obj);

    this.authService.listPackage(obj).subscribe((listPackageRes: any) => {
     
      console.log('list package response', listPackageRes);
      this.rowData=listPackageRes;
      if (listPackageRes.error === '' || listPackageRes.error === undefined) {
        this.toastr.success('', 'Show list of package', {
          disableTimeOut: true,
          tapToDismiss: false,
          closeButton: true
        });
        
      } else {
        this.toastr.error(listPackageRes.error['Error Description'], listPackageRes.error['Error Msg'], {
          disableTimeOut: true,
          tapToDismiss: false,
          closeButton: true
        });
      }
    });
  }

  sortBy(value){
    if(value == 'asc'){
      this.obj.sort(function(a, b){
        return a.packageTitle.localeCompare(b.packageTitle);
    })
    
    }
    if(value == 'desc'){
      this.obj.sort(function(a, b){
        return b.packageTitle.localeCompare(a.packageTitle);
    })
    }
  }

Search(){
  
if(this.term)
{
  
  let  data= this.tempObj.filter(res=> res.packageTitle.toLowerCase().indexOf(this.term.toLowerCase()) >= 0 );
  console.log(data)
  this.obj = data
  this.p
  this.perPageItem
 }
else{
 this.obj =  this.tempObj 
}
}

}
