import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from ".././localStorage/local-storage.service";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

      getCurrentUserId:string;
      
  constructor(private httpClient: HttpClient, private localStorage: LocalStorageService, private toastr: ToastrService) {
    console.log('user logged in storage ', this.localStorage.getUserLoggedIn());
    let timeleft;
    // if user is already loggedIn
    if (this.localStorage.getUserLoggedIn() === 'true' && this.localStorage.getLoggedInUserData()) {
      // set user permission to 'yes'
      this.userPermission.next('yes');
      let interval = setInterval(() => {
        timeleft = this.localStorage.getSession() - Date.now();
        // console.log('timeleft', timeleft);
        if(timeleft < 0) {
          console.log('session expired');
          this.localStorage.clearLocalStorage();
          this.localStorage.setUserLoggedInFalse();
          this.toastr.warning('Your session is expired. Close this popup or reload the page and Login again.', 'Warning!!!', {
            disableTimeOut: true,
            tapToDismiss: false,
            closeButton: true
          }).onHidden.subscribe(() => {
            console.log('toastr closed');
            location.reload();
          });
          clearInterval(interval);
        }
      }, 1000);
    } else {
      this.localStorage.clearLocalStorage();
      this.localStorage.setUserLoggedInFalse();
    }
  }

  // base url of authentication api endpoints
  authBaseURL = environment.authApiURL;
  // base url of private api endpoints
  apiBaseURL = environment.apiURL;

  // userpermission to check user is loggedIn or not
  userPermission = new BehaviorSubject('no');

  // after user loggedIn
  afterLogin() {
    // set user permission to 'yes'
    this.userPermission.next('yes');
  }

  // register user api
  registerUser(data) {
    console.log('registeration', this.authBaseURL);
    return this.httpClient.post(this.authBaseURL + '/investorRegister', data);
  }

  // login user api
  loginUser(data) {
    return this.httpClient.post(this.authBaseURL + '/userLogin', data);
  }
  // logout user api
  logoutPreviousSession(userId) {
    return this.httpClient.post(this.authBaseURL + '/logoutPreviousSession', userId);
  }

  // logout user api
  logoutUser(data) {
    return this.httpClient.post(this.apiBaseURL + '/Logout', data);
  }

  // forgot user password api
  forgotUserPassword(data) {
    return this.httpClient.post(this.authBaseURL + '/forgotPassword', data);
  }
  // user-info api
  userInfo(data) {
    return this.httpClient.post(this.apiBaseURL + '/userInfo', data);
  }
   //update profile details api
  updateProfileimage(data)
  {
    return this.httpClient.post(this.apiBaseURL + '/updateProfileimage', data);
  }
// user update details api
  updateUserDetails(data){
    return this.httpClient.post(this.apiBaseURL + '/updateUserDetails', data);
  }

  // password change api
  passwordChange(data)
  {
    return this.httpClient.post(this.apiBaseURL + '/passwordChange', data);
  }

  //list package api
  listPackage(data)
  {
    return this.httpClient.post(this.apiBaseURL + '/listPackage', data);
  }

  //reset password api
  resetPassword(data)
  {
    return this.httpClient.post(this.authBaseURL + '/resetPassword', data);
  }
  detailedPackage(data)
  {
    return this.httpClient.post(this.apiBaseURL + '/detailedPackage', data);
  }
}
