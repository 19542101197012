<!-- pageWrapper -->
<div id="pageWrapper">
  <!-- pageMenuPushWrap -->
  <div class="pageMenuPushWrap pageMenuPushWrap1">
    <!-- bgBaseWrap -->
    <div class="bgBaseWrap">
      <!-- pageHeader -->
      <app-header></app-header>
        <br/>
        <br/>
      <!-- main -->
      <main>
         
        <!-- content -->
        <section id="content" class="container pabIndent">
          <!-- contentFiltersHeadingWrap -->
          <header class="contentFiltersHeadingWrap row">
            <div class="col-xs-12 col-sm-10">
              <!-- breadcrumb -->
              <ol class="breadcrumb breadcrumb1">
                <li><a href="#">Home</a></li>
                <li class="active">Profile</li>
              </ol>
              <h1 class="fontNeuron">Profile</h1>
            </div>
            <div class="col-xs-12 col-sm-2">
              <!-- viewFilterLinks -->
              <ul class="list-unstyled viewFilterLinks">
                <li><a href="#"><i class="fi flaticon-share"></i></a></li>
                <li><a href="#"><i class="fi flaticon-printer"></i></a></li>
              </ul>
            </div>
          </header>
          <!-- userProfile -->
          <div class="userProfile">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-lg-8">
                <!-- accountData -->
                <div class="accountData style2">
                  <div class="accountHolder">
                    <div class="imgProfile">
                      <div class="imgThumbnail">
                        <img *ngIf="!image" src="https://via.placeholder.com/320x340" alt="image description">
                       
                          <img *ngIf="image" [src]="image" alt="" width="320" height="340">
                      </div>
                    </div>
                    <div class="accountContent">
                      <strong class="title fontNeuron">{{userName}}</strong>
                      <span class="sub-title">{{userType}}</span>
                      <p>Ut euismod ultricies sollicitudin. Curabitur sed dapibus neque. Nam in mauris quis libero sodales eleifend. tristique turpis, et iaculis mi tortor finibus.</p>
                      <div class="contactInfo">
                        <address class="adr">
                          <div class="item">
                            <i class="fi flaticon-24-hours"></i>
                            <span class="text"><a href="tel:+12463450695">+1 246-345-0695</a></span>
                          </div>
                          <div class="item">
                            <i class="fi flaticon-mail"></i>
                            <span class="text"><a href="mailto:info@LemanHouse.com">{{email}}</a></span>
                          </div>
                        </address>
                      </div>
                      <ul class="list-unstyled socialNetworks profileColumnSocial">
                        <li><a href="#" tabindex="0"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" tabindex="0"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" tabindex="0"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="#" tabindex="0"><i class="fab fa-google"></i></a></li>
                      </ul>
                      <span class="btn btnSmall btn-success text-capitalize">For Sale: 12</span>
                      <span class="btn btnSmall btn-info text-capitalize">For Rent: 14</span>
                    </div>
                  </div>
                </div>
                <div class="accountData">
                  <form>
                    <div class="head">
                      <h4 class="fontNeuron">Biography</h4>
                    </div>
                    <p>Emmy also enjoys spending time with her 2 King Charles Cavalier Spaniels, participating in Motocross events with Tye as well as sponsoring local community events.</p>
                    <p>– 2015 Real Estate Results Network – Boutique Principal of the Year – Winner</p>
                    <p>– 2015 Real Estate Results Network – QLD Agent of the Year – Winner</p>
                    <p>– 2015 Real Estate Results Network – Regional Boutique Agency of the Year – Winner</p>
                  </form>
                </div>
              
              
              </div>
              <div class="col-xs-12 col-sm-12 col-lg-4">
                <!-- contactFormPage -->
              </div>
            </div>
          </div>
        </section>
        <!-- signupFormAside -->
        <form action="#" class="bgWhite signupFormAside">
          <div class="container signupFormAsideHolder">
            <span class="icnAbsolute elemenBlock fi flaticon-message"></span>
            <div class="col-xs-12 col-md-offset-2 col-md-6">
              <h3 class="fontNeuron textSecondary">Sign up for our newsletter.</h3>
              <p>Lorem molestie odio. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
            </div>
            <div class="col-xs-12 col-md-4">
              <div class="form-group">
                <input type="email" id="email" class="form-control elemenBlock" placeholder="Your email">
                <button type="button" class="buttonReset fi flaticon-navigation"></button>
              </div>
            </div>
          </div>
        </form>
      </main>
    </div>
    <!-- pageFooterBlock -->
    <app-footer></app-footer>
  </div>
</div>