
            <app-header></app-header>
            <br/>
            <br/>
            <br/>
                <form [formGroup]="resetPasswordForm" (submit)="resetPasswordForm.valid && resetPassword()">
                    <div class="col-xs-12 col-md-6">
                        <h4 class="fontNeuron">Reset Password</h4>
                    
                                    <div class="form-group">
                                        
                                        <input type="password" class="form-control elemenBlock" placeholder="Password"
                                            formControlName="password">
                                        <div class="invalid-feedback"
                                            *ngIf="resetPasswordForm.get('password').touched && resetPasswordForm.get('password').invalid">
                                            <span class="text-danger"
                                                *ngIf="resetPasswordForm.get('password').errors.required">Enter
                                                password.</span>
                                            <span class="text-danger"
                                                *ngIf="resetPasswordForm.get('password').hasError('minlength')">Minimum
                                                length should be 6.<br /></span>
                                        </div>
                                    </div>
                               
                                 <button type="submit" class="btn btnSecondary buttonL fontNeuron"
                                [disabled]="resetPasswordForm.invalid || isLoadingResetPass">Reset
                                Password<img *ngIf="isLoadingResetPass" class="loadingImg"
                                    src="assets/images/loading.gif" alt="loading"></button>
                        </div>
  
                </form>
            
    