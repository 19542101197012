<!-- pageFooterBlock -->
<div class="pageFooterBlock">
  <!-- pageAside -->
  <aside class="pageAside textWhite">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-4 col">
          <h2 class="fontNeuron fwSemi text-uppercase">LemanHouse</h2>
          <p>Lorem ipsum dolor amet, consectetur adipiscing elit. Sed ut purus eget nunc ut dignissim cursus.</p>
          <address>
            <dl>
              <dt><i class="fi flaticon-navigation"></i></dt>
              <dd>Collins Street West, Victoria 8007, Australia.</dd>
              <dt><i class="fi flaticon-24-hours"></i></dt>
              <dd><a href="tel:+12463450695">+1 246-345-0695</a></dd>
              <dt><i class="fi flaticon-mail"></i></dt>
              <dd><a
                  href="mailto:&#105;&#110;&#102;&#111;&#064;&#104;&#111;&#109;&#101;&#115;&#119;&#101;&#101;&#116;&#046;&#099;&#111;&#109;">&#105;&#110;&#102;&#111;&#064;&#104;&#111;&#109;&#101;&#115;&#119;&#101;&#101;&#116;&#046;&#099;&#111;&#109;</a>
              </dd>
            </dl>
          </address>
        </div>
        <!-- ftNav -->
        <nav class="col-xs-12 ftNav col-sm-4 col">
          <h2 class="fontNeuron fwSemi text-uppercase">Useful Links</h2>
          <div class="ftNavListsHolder">
            <ul class="list-unstyled">
              <li><a href="#">Terms of Use</a></li>
              <li><a href="#">About US</a></li>
              <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Contact Support</a></li>
              <li><a href="#">Careers</a></li>
              <li><a href="#">FAQs</a></li>
            </ul>
            <ul class="list-unstyled">
              <li><a href="#">FAQ</a></li>
              <li><a href="#">Blog</a></li>
              <li><a href="#">Our Agents</a></li>
              <li><a href="#">How It Works</a></li>
              <li><a href="#">Contact</a></li>
            </ul>
          </div>
        </nav>
        <div class="col-xs-12 col-sm-4 col">
          <h2 class="fontNeuron fwSemi text-uppercase">LATEST PROPERTIES</h2>
          <!-- postsWidgetList -->
          <ul class="list-unstyled postsWidgetList">
            <li>
              <div class="alignleft">
                <a href="#">
                  <img src="https://via.placeholder.com/90x70" alt="image description">
                </a>
              </div>
              <div class="descrWrap">
                <h3 class="fwNormal"><a href="#">Do what you love and tomorrow will pay</a></h3>
                <h4 class="fwSemi">$ 1,27,000</h4>
              </div>
            </li>
            <li>
              <div class="alignleft">
                <a href="#">
                  <img src="https://via.placeholder.com/90x70" alt="image description">
                </a>
              </div>
              <div class="descrWrap">
                <h3 class="fwNormal"><a href="#">We’re ready for the TRID rules!</a></h3>
                <h4 class="fwSemi">$ 527,000</h4>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </aside>
  <!-- pageFooter -->
  <footer id="pageFooter">
    <div class="container pageFooterHolder">
      <div class="row">
        <div class="col-xs-12 col-sm-push-6 col-sm-6">
          <!-- pageFooterSocial -->
          <ul class="socialNetworks list-unstyled pageFooterSocial">
            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
            <li><a href="#"><i class="fab fa-instagram"></i></a></li>
            <li><a href="#"><i class="fab fa-pinterest"></i></a></li>
            <li><a href="#"><i class="fab fa-dribbble"></i></a></li>
            <li><a href="#"><i class="fab fa-google"></i></a></li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-pull-6 col-sm-6">
          <p>2017 <a href="#">LemanHouse</a> - All rights reserved</p>
        </div>
      </div>
    </div>
  </footer>
  <!-- btnScrollToTop -->
  <a href="#pageWrapper" class="btnScrollToTop smooth textWhite">Scroll Top <i
      class="fi flaticon-arrows btnScrollIcn"></i></a>
  <span class="bgCover elemenBlock" style="background-image: url(https://via.placeholder.com/1920x520);"></span>
</div>