import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastrService } from "ngx-toastr";
import { LocalStorageService } from "../services/localStorage/local-storage.service";
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'app-premium-package-detail',
  templateUrl: './premium-package-detail.component.html',
  styleUrls: ['./premium-package-detail.component.css']
})
export class PremiumPackageDetailComponent implements OnInit {

  userDetail:any;
  userInfoData:any;
  image:any;
  username:any;
  constructor(private authService: AuthService, private toastr: ToastrService, private localStorage: LocalStorageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userDetail=this.localStorage.getLoggedInUserData();
    
    const obj={
     
       idUser: this.userDetail.idUser,
       publicKey: this.userDetail.Key.publicKey,
       sessionKey: this.userDetail.Key.sessionKey,
       accessKey: "",
       userType: this.userDetail.userType
     }
         //call user info api
     this.authService.userInfo(obj).subscribe((userInfoRes: any) => {
       this.userInfoData= userInfoRes.data;
     this.image= this.userInfoData.avatar;
     this.username=this.userInfoData.username;
 
    
     });

  }

  detailedPackage()
  {
    
  }


  obj=
    {
      "idPackage": "1",
      "idPackageType": 10,
      "packageTitle": "Package1",
      "packageSlug": "DIM-EUR",
      "idPairSource": "1",
      "idPairTarget": "2",
      "initialValue": "100",
      "lockupPeriod": 12,
      "releasedAt": "2020-10-29T00:00:00+00:00",
      "isPrimary": "1",
      "isActive": "1"
    }
 
  
}
